import React from "react";
import styled from "styled-components";
import YakshaganaStringPuppetsSection from "../components/forms/TwoColContactUsWithIllustration";

const SliderMain = styled.div`
  margin-bottom: 50px;
  margin-left: 100px;
  padding: 100px;
  padding-top: 0;
  @media (min-width: 320px) and (max-width: 480px) {
    padding: 10px;
    margin-left: 0;
  }
  .image-gallery-slide-wrapper {
    background: black !important;
  }
  h1 {
    font-size: 24px;
    font-weight: bold;
  }
  h2 {
    font-size: 20px;
    font-weight: bold;
  }
  ul {
    margin: 20px 0 20px 50px;
    list-style: decimal-leading-zero;

    li {
      padding: 5px;
    }
  }
`;

export default function AppealToPublic() {
  return (
    <div>
      <YakshaganaStringPuppetsSection
        title={"Appeal to Public"}
        image1={null}
      />

      <SliderMain>
        <br />
        <p>
          It gives me great joy to reach out to the public and masses with this
          appeal to support me in saving a folk art,” Indian puppetry” which is
          now in its last breath. My ancestors about 350 years back brought this
          rare folk art into practice and I am in the 6 th generation in such a
          family which is struggling hard to keep this folk art alive. Ours is a
          Trust with the name “Uppinakudru Sri Devanna Padmanabha Kamath
          Memorial Yakshagana Gombeyata Trust (R )” established in the year 1995
          with a purpose of keeping this art alive to the present and future
          generations. We have shown to the society that in the absence of any
          financial or moral support from the state or union governments, we
          have been successful in getting encouragement from the public of this
          state of Karnataka, this country as well as many countries of the
          globe. I wish to name two important and generous personalities who are
          the sponsors of our Trust and they are Dr. Sudhamurthy, Chairperson of
          Infosys Foundation, Bengaluru and Dr. P. Dayananda Pai, Managing
          Director of Century Builders Pvt. Ltd. Bengaluru. With their financial
          and moral support today we have a huge building where we conduct
          educational, cultural and other programmes in this huge theatre have
          good response from the audience. Thanks to these two honourable donors
          and sponsors.
        </p>
        <br />
        <p>
          We are very much pleased to make a mention that we have been
          successful in conducting 61 monthly programmes right from 2015 and
          have given encouragement to the participants to expose their talents
          on the stage. This is nothing but a herculean task in this remote
          village of Uppinakudru. These programmes are conducted under the
          banner “Talent is yours Stage is ours” . We have thought of
          celebrating the Centanarian programme of Late Sri Kogga Devanna Kamath
          who is considered as the Giant and Master of String Puppetry. Besides
          we have proposal of celebrating the Silver Jubilee Function of the
          Trust simultaneously along with the Centinary celebrations shortly.
        </p>
        <br />
        <p>
          We earnestly request you to take our achievements into consideration
          and extend your support by donating your might to the Trust so that we
          shall go ahead with our projects and programmes and do justice to the
          folk art “Puppetry”. We have our Bank accounts as here under and
          contributions may please be credited to the below account numbers.
          Such contributions have exemption from the Income Tax Act under 80 G
          and 12 A sections.
        </p>
        <br />
        <p>
          Finally, we sincerely request you to consider our appeal so that the
          350 years old traditional folk art of coastal Karnataka will survive
          for the future generation and join our hands to save Indian Culture,
          tradition etc.
        </p>
        <br />
        <p>
          1. State Bank of India – SB A/C. No. 54034833919. IFSC- SBIN0040150{" "}
          <br />
          2.Canara Bank – SB A/C No. 0603101051157- IFSC – CNRB 0000603
        </p>
        <br />
        <br />
        <h2>
          <strong>OUR IMMEDIATE REQUIREMENTS FOR PUPPET AKADEMY</strong>
        </h2>
        <ul>
          <li>Statue of Kogga Kamath and Devanna Padmanabha Kamath</li>
          <li>Generator Facility</li>
          <li>Mike Sets and Speakers</li>
          <li>Stage Light system</li>
          <li>Computer Section</li>
          <li>Digital Screen</li>
          <li>Solar Light system</li>
          <li>Museum Set</li>
          <li>Furnitures</li>
          <li>Aquaguard</li>
          <li>Laptop</li>
          <li>Puppet Production</li>
          <li>Oil Paintings of Puppets</li>
          <li>Solar Water Heater</li>
          <li>Projector</li>
          <li>Wall Fans</li>
        </ul>
      </SliderMain>
    </div>
  );
}
