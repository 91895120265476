import React from "react";
import VerticalWithAlternateImageAndText from "../../components/features/VerticalWithAlternateImageAndText";

// var content = ""
// for(i=1; i<=19; i++){
//    content += `
//         {
//             src: image${i},
//           },
//     `
// }
export default function FamilyBackground() {
  return (
    <div>
      <VerticalWithAlternateImageAndText />
    </div>
  );
}
