import React from "react";
import styled from "styled-components";
import tw from "twin.macro";
//eslint-disable-next-line
import { css } from "styled-components/macro"; //eslint-disable-line
import {
  SectionHeading,
  Subheading as SubheadingBase,
} from "components/misc/Headings.js";
import { SectionDescription } from "components/misc/Typography.js";

import { ReactComponent as SvgDecoratorBlob3 } from "images/svg-decorator-blob-3.svg";

const Container = tw.div`relative`;

const ThreeColumnContainer = styled.div`
  ${tw`flex flex-col items-center md:items-stretch md:flex-row flex-wrap md:justify-center max-w-screen-lg mx-auto py-20 md:py-24`}
`;
const Subheading = tw(SubheadingBase)`mb-4`;
const Heading = tw(SectionHeading)`w-full`;
const Description = tw(SectionDescription)`w-full text-center`;

const VerticalSpacer = tw.div`mt-10 w-full`;

const Column = styled.div`
  ${tw`md:w-1/2 lg:w-1/3 max-w-sm`}
`;

const Card = styled.div`
  ${tw`flex flex-col sm:flex-row items-center sm:items-start text-center sm:text-left h-full mx-4 px-2 py-8`}
  .imageContainer {
    ${tw`border text-center rounded-full p-5 flex-shrink-0`}
    img {
      ${tw`w-6 h-6`}
    }
  }

  .textContainer {
    ${tw`sm:ml-4 mt-4 sm:mt-2`}
  }

  .title {
    ${tw`mt-4 tracking-wide font-bold text-2xl leading-none`}
  }

  .description {
    ${tw`mt-1 sm:mt-4 font-medium text-secondary-100 leading-loose`}
  }
`;

const DecoratorBlob = styled(SvgDecoratorBlob3)`
  ${tw`pointer-events-none absolute right-0 bottom-0 w-64 opacity-25 transform translate-x-32 translate-y-48 `}
`;

export default ({
  cards = null,
  heading = "BHASKAR KOGGA KAMATH",
  subheading = "Location",
  description = "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
}) => {
  return (
    <Container>
      {subheading && (
        <Subheading
          style={{
            margin: "0 auto",
            textAlign: "center",
          }}
        >
          {subheading}
        </Subheading>
      )}
      <Heading>{heading}</Heading>
      <Description
        style={{
          margin: "0 auto",
        }}
      >
        <h1 style={{ color: "#6417ff" }}>
          <b>MASTER PUPPETEER & PRESIDENT</b>
        </h1>
        <h2 style={{ color: "#6417ff" }}>
          UPPINAKUDRU SHRI DEVANNA PADMANABHA KAMATH MEMORIAL YAKSHAGANA
          GOMBEYATA TRUST (R)
        </h2>
        <br />
        <p style={{ marginBottom: "15px", fontWeight: "bold" }}>AND</p>
        <p
          style={{
            fontSize: "15px",
          }}
        >
          SHRI GANESH YAKSHAGANA GOMBEYATA MANDALI, <br />
          PUPPET HOUSE, POST UPPINAKUDRU - 576 230, KUNDAPURA TALUK, <br />
          UDUPI DISTRICT, KARNATAKA, INDIA. <br />
          Mobile: +91 94484 37848
        </p>
        <br />
        <p>
          E-Mail: <br />
          <a
            style={{ color: "blue" }}
            href="mailto:puppeteerkamathbk@yahoo.com"
          >
            puppeteerkamathbk@yahoo.com
          </a>
          <br/>
          <a
            style={{ color: "blue" }}
            href="mailto:puppeteerkamathbk@gmail.com"
          >
            puppeteerkamathbk@gmail.com
          </a>
        </p>
      </Description>
      <br />
      <iframe
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3877.040946442325!2d74.69125151409307!3d13.655273190409963!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bbc8f12586aebf1%3A0x835909ebb5e59197!2zUHVwcGV0IEhvdXNlIOCyl-CziuCyguCyrOCzhiDgsq7gsqjgs4Y!5e0!3m2!1sen!2sin!4v1600619172147!5m2!1sen!2sin"
        width="600"
        height="450"
        frameborder="0"
        style={{ border: 0, marginTop: "20px" }}
        allowfullscreen=""
        aria-hidden="false"
        tabindex="0"
        style={{
          margin: "0 auto",
        }}
      ></iframe>
      <DecoratorBlob />
    </Container>
  );
};
