import React from "react";
import ImageGallery from "react-image-gallery";
import styled from "styled-components";
import YakshaganaStringPuppetsSection from "../../components/forms/TwoColContactUsWithIllustration";
import image1 from "./Gallary/01.Awards/01.National Award -Kogga kamath.JPG";
import image2 from "./Gallary/01.Awards/01.National Award-Devanna Kamath.JPG";
import image3 from "./Gallary/01.Awards/01.Karnataka State Award- Bhaskar Kogga Kamath.jpg";
import image4 from "./Gallary/01.Awards/02.Tulasi Sanmana - Kogga Devanna Kamath.jpg";
import image5 from "./Gallary/01.Awards/02.Vishwa Konkani Award- Bhaskar Kogga Kamath.jpg";
import image6 from "./Gallary/01.Awards/03.Falicitaion in FRANCE TOUR-2012 070.jpg";
import image7 from "./Gallary/01.Awards/04.Felicitation from K.K.Pai - Bhaskar Kogga Kamath.jpg";
import image8 from "./Gallary/01.Awards/05.Devanna Kamath Receiving Award.jpg";
import image9 from "./Gallary/01.Awards/05.Kala Chethana Award - Bhaskar Kogga Kamath.jpg";
import image10 from "./Gallary/01.Awards/06.Prof.Vajra Kumara Abhinandana Award - Bhaska Kogga Kamath.jpg";
import image11 from "./Gallary/01.Awards/07.Falicitation in Kala Kuncha - Bhaskar Kogga Kamath.jpg";
import image12 from "./Gallary/01.Awards/08.Shambhu Hegde Award.jpg";
import image13 from "./Gallary/01.Awards/09.Falicitation to Bhaskar kamath.jpg";
import image14 from "./Gallary/01.Awards/10.Falicitation to Bhaskar Kamath.jpg";
import image15 from "./Gallary/01.Awards/11.Falicitation.jpg";
import image16 from "./Gallary/01.Awards/12.jpg";

const images = [
  {
    original: image1,
    thumbnail: image1,
    description: "National Award - Kogga kamath",
  },
  {
    original: image2,
    thumbnail: image2,
    description: "National Award - Devanna Kamath",
  },
  {
    original: image3,
    thumbnail: image3,
    description: "Karnataka State Award - Bhaskar Kogga Kamath",
  },
  {
    original: image4,
    thumbnail: image4,
    description: "Tulasi Sanmana - Kogga Devanna Kamath",
  },
  {
    original: image5,
    thumbnail: image5,
    description: "Vishwa Konkani Award - Bhaskar Kogga Kamath",
  },
  {
    original: image6,
    thumbnail: image6,
    description: "Felicitation in FRANCE",
  },
  {
    original: image7,
    thumbnail: image7,
    description: "Felicitation from K.K.Pai - Bhaskar Kogga Kamath",
  },
  {
    original: image8,
    thumbnail: image8,
    description: "Devanna Kamath Receiving Award",
  },
  {
    original: image9,
    thumbnail: image9,
    description: "Kala Chethana Award - Bhaskar Kogga Kamath",
  },
  {
    original: image10,
    thumbnail: image10,
    description: "Prof.Vajra Kumara Abhinandana Award - Bhaska Kogga Kamath",
  },
  {
    original: image11,
    thumbnail: image11,
    description: "Felicitation in Kala Kuncha - Bhaskar Kogga Kamath",
  },
  {
    original: image12,
    thumbnail: image12,
    description: "Shambhu Hegde Award",
  },
  {
    original: image13,
    thumbnail: image13,
    description:
      "“Virudu” Award to Bhaskar Kamath by Madras Craft Foundation-Chennai",
  },
  {
    original: image14,
    thumbnail: image14,
    description:
      "Felicitation by Hon.‘P.B Acharya’ Former Governor of Nagaland",
  },
  {
    original: image15,
    thumbnail: image15,
    description: "Felicitation",
  },
  {
    original: image16,
    thumbnail: image16,
    description: "TMA Pai Award - Bhaskar Kogga Kamath",
  },
];

const SliderMain = styled.div`
  margin-bottom: 50px;
  padding: 100px;
  padding-top: 0;
  @media (min-width: 320px) and (max-width: 480px) {
    padding: 10px;
    margin-left: 0;
  }
  .image-gallery-slide-wrapper {
    background: black !important;
  }

`;

export default function GalleryAwards() {
  return (
    <div>
      <YakshaganaStringPuppetsSection title={"Awards"} image1={null} />
      <SliderMain>
        <ImageGallery items={images} useTranslate3D={true} />
      </SliderMain>
    </div>
  );
}
