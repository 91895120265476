import React from "react";
import styled from "styled-components";
import tw from "twin.macro";
import HeaderBase from "components/headers/light.js";
import { SectionHeading } from "components/misc/Headings.js";
import { SectionDescription } from "components/misc/Typography.js";
import { PrimaryButton as PrimaryButtonBase } from "components/misc/Buttons.js";
import {
  Container,
  ContentWithVerticalPadding,
} from "components/misc/Layouts.js";
import { ReactComponent as CheckboxIcon } from "feather-icons/dist/icons/check-circle.svg";
import { ReactComponent as QuotesLeftIconBase } from "images/quotes-l.svg";
import { ReactComponent as SvgDecoratorBlob1 } from "images/dot-pattern.svg";
import DevannaKamath from "../../pages/about-us/photos/DevannaKamath/03.Bhaskar Kamath with Puppet.jpg";
import DevannaKamath2 from "../../pages/about-us/photos/DevannaKamath/Bhaksar Kamath Page-min.jpg";
import DevannaKamath3 from "../../pages/about-us/photos/DevannaKamath/01.Bhaskar Kamath with Puppets.jpg";
import DevannaKamath4 from "../../pages/about-us/photos/DevannaKamath/02.Bhaskar Kogga Kamath with Puppet.jpg";
import DevannaKamath5 from "../../pages/about-us/photos/DevannaKamath/04.Bhaskar Kogga Kamath with Puppets.jpg";
import DevannaKamath6 from "../../pages/about-us/photos/DevannaKamath/06.Bhasakr Kogga Kamath with Puppets.jpg";
import renderHTML from "react-render-html";

const Header = tw(HeaderBase)`max-w-none`;
const Row = tw.div`flex flex-col lg:flex-row justify-between items-center max-w-screen-2xl mx-auto sm:px-8`;
const Column = tw.div``;
const TextColumn = tw(
  Column
)`mr-auto lg:mr-0 max-w-lg lg:max-w-xl xl:max-w-2xl`;
const Heading = tw(
  SectionHeading
)`text-left text-primary-900 leading-snug xl:text-6xl`;
const Description = tw(
  SectionDescription
)`mt-4 lg:text-base text-gray-700 max-w-lg`;
const PrimaryButton = tw(
  PrimaryButtonBase
)`mt-8 inline-block w-56 tracking-wide text-center py-5`;
const FeatureList = tw.ul`mt-12 leading-loose`;
const Feature = tw.li`flex items-center relative`;
const FeatureIcon = tw(CheckboxIcon)`w-5 h-5 text-primary-500 absolute`;
const FeatureText = tw.p`ml-2 font-medium text-gray-700`;
const ImageColumn = tw(Column)`ml-auto lg:mr-0 relative mt-16 lg:mt-0 lg:ml-32`;
const ImageContainer = tw.div`relative z-40 transform xl:-translate-x-24 xl:-translate-y-16`;
const Image = tw.img`max-w-full w-96 rounded-t sm:rounded relative z-20`;
const Offsetbackground = tw.div`absolute inset-0 bg-gray-300 rounded xl:-mb-8`;
const ImageDecoratorBlob = styled(SvgDecoratorBlob1)`
  ${tw`pointer-events-none z-10 absolute right-0 bottom-0 transform translate-x-10 translate-y-10 h-32 w-32 opacity-25 text-gray-900 fill-current`}
`;
const Testimonial = tw.div`max-w-sm rounded-b md:rounded-none relative sm:absolute bottom-0 inset-x-0 z-20 px-8 py-6 sm:px-10 sm:py-8 bg-primary-900 text-gray-400 font-medium transform md:-translate-x-32 text-sm leading-relaxed md:-mr-16 xl:mr-0`;
const QuotesLeftIcon = tw(
  QuotesLeftIconBase
)`w-16 h-16 md:w-12 md:h-12 absolute top-0 left-0 text-gray-100 md:text-red-500 transform translate-x-1 md:-translate-x-1/2 md:-translate-y-5 opacity-10 md:opacity-100`;
const Quote = tw.blockquote``;
const CustomerName = tw.p`mt-4 font-bold`;
const CustomerCompany = tw.p`mt-1 text-sm text-gray-500`;

export default ({
  imageDecoratorBlob = true,
  features1 = [
    "1978 - France tour, 1979 - Belgium, Holland, 1983 - Australia",
    "1983 - France, Geneva, Holland.",
    "1985 - Germany, France, Holland, Reunion . .1986 - Greece, and Athens.",
    "1990 - participated in Holland Puppet Festival.",
    "1994 - participated in National Puppet Festival held at Assam . Subsequently, he secured Central Government Junior Research Fellowship for a period of five years",
    "1995 - authored a book in English Story of Kogga Kamath Merionets, giving complete story of Uppinakudru Kamath Gharana Stringed puppetry. This is first documentation work in Uppinakudru Kamath Gharana. He also authored a Book in Kannada about the Foreign Travels of his Puppet Troupe, under the auspices of the Trust, which he founded, viz. UPPINAKUDRU SRI DEVANNA PADMANABHA KAMATH MEMORIAL YAKSHAGANA GOMBEYATA TRUST (REGD.)",
    "1996 - participated in International Puppet Festival held at Pakistan.",
    "1997- toured Andra Pradesh and Punjab states (Jalandar, Amrithsar, Ludhiana, Bhutiala, Ropar etc.)",
    "Authored GOMBE PRAPANCHA a Souvenier in Kannada in 1998.",
  ],
  features2 = [
    "He successfully participated in KONKANI KALA UTSAV held at New Delhi in Sept. 1998, introduced nerration in KONKANI language for the first time. In December he travelled Kolkatta for participating in International Puppet Festival.",
    "Nov.1999 - elected a member of the KARNATAKA JANAPADA & YAKSHAGANA ACADEMY, BANGALORE.",
    "1999 - France tour.",
    "In his zealous devotion to this ancient family traditional Folk-art, Bhaskar Kamath even declined a lucrative job offered by a scheduled bank.",
    "January 2000 -participated with practical demonstration in the INFOSIS PUPPET FESTIVAL at Bangalore organised by well-known philanthropic institution  INFOSIS FOUNDATION",
    "He successfully participated in the International Puppet Festival held at New Delhi in October 2000 sponsored by I.C.C.R.",
    "Nov. 2001- National Puppet Festival organised by PRITHVI THEATRE Mumbai.",
    "Feb. 2002 - Bhaskar Kamath felicited from 69th All India Kannada Sammelana - Tumkur.",
  ],
  features3 = [
    "March. 2002 - National Puppet Festival held at Chennai by MADRAS CRAFT FOUNDATION.",
    "June. 2002 - SPIC-MACAY National Convention at Dehra Dun",
    "Sept. 2002 - International Puppet Festival organised by Ishar Puppet Theatre New Delhi and Breath-taking successful shows ( at great personal risk ) at JAMMU & KASHMIR ( for Army Jawans ) ",
    "Successful tours at GOA in January 2003.",
    "Inaugural show in the International Puppet Festival in March 2003, organised by Sangeeth Natak Academy, New Delhi.",
    "Opening ceremony of GOMBE MANE (Puppet House ) at Uppinakudru in May 2003.",
    "December 2003 - Hyderabad Tour organised by NATYARANGA & SPIC- MACAY.",
    "August 2004 - authored a book <b className='bold'>SOOTRA PARVA</b>, a complete memoir on the life of late KOGGA DEVANNA KAMATH.",
    "Sept. 2004 - one month Rajastan Tour sponsored by SPIC-MACAY. Introduced Narration in Hindi language for the first time.",
    "March 2005 - Participated in National Puppet Festival held at Mumbai organised by Sangeeth Natak Academy, New Delhi.",
  ],
  features4 = [
    "March 2005 - Participated in 20th NATIONAL CONVENTION held at Manipal sponsored by SPIC-MACAY & MAHE.",
    "He alone has given practical demonstration in manipulation of the Yakshagana string puppetry during several national & international festival. Bhaskar Kamath has trained many students like Indian as well as foreign students in Yakshagana puppetry.",
    "September 2005 - Conducted training course for 10 students under 'GURUKULA' system.",
    "January 2006 - Honoured by Scientist Sri. Kasturirangan at Tata Institute, Bangalore.",
    "March 2006 - Received '<b className='bold'>LIFE TIME ACHIEVEMENT AWARD</b>' (VIRUDU) by Madras Craft Foundation at Chennai.",
    "May 2007 - Inauguration of '<b className='bold'>IRAVATHA</b>', Mini Bus, donated by INFOSYS FOUNDATION BANGALORE.",
    "May 2007 - Participated in programme sponsored by AZIM PREMJI FOUNDATION, (WIPRO), Bangalore.",
    "August 2007 - Andhra Pradesh Tour organised by SPIC-MACAY.",
    "September 2007 - London Tour for 2 months - sponsored by Annapurna Indian Dance Company, Yorkshire, London.",
    "November 2007 - Japan Tour - sponsored by Foundation Modern Puppet Centre.",
    "February 2008 - '<b className='bold'>ONE PROGRAMME FOR ONE DISTRICT</b> '<b className='bold'>UPPINAKUDRU PUPPET TOUR -2008</b>' - Karnataka Tour- First time in the history of Uppinakudru Yakshagana Puppetry- sponsored by Infosys Foundation, Bangalore.",
    "February 2008 - Received '<b className='bold'>KALA CHETHANA</b>' award from Kalachethana,Academy Gadag.",
  ],
  features5 = [
    "June 2008 - Received '<b className='bold'>Prof. VAJRA KUMAR AWARD</b>' from Dr. Veerendra Hegade at Vidyagiri, Dharwad.",
    "Sept. 2008 -visited Interlaken, Swizerland for demonstration.Jan. 2009 - visited Singapore along with the Troupe, This tour is sponsored by Dr. Sudhamurthy, Chairperson, Infosys Foundation.",
    "'<b className='bold'>SOOTRA ABHIYANA</b>' (Kannada) and Colour pamphlets (English) <b className='bold'>THE STRING TRADITION AND UPPINAKUDRU YAKSHAGANA STRING PUPPETS</b> - Book and Pamphlet releasing function held on 19th April 2009 at Kundapura.",
    "Nov. 2009, - Participated in <b className='bold'>International Pupepet Festival</b> held at <b className='bold'>Thailand</b> on the <b className='bold'>occasion of King’s Birthday Celebration</b> sponsored by I.C.C.R. New Delhi.",
    "April 2010 - Bhaskar Kamath Selected as a <b className='bold'>Panel Member of Fellowship Selection Committee</b> under Cultural Ministry, Govt. Of India, New Delhi.",
    "May 2010 – Visited <b className='bold'>London (Bharatiya Vidya Bhavan, U.K.)</b> with the Troupe for programme <b className='bold'>sponsored by Dr. Sudhamurthy, Chair Person, Infosys Foundation</b>, Bangalore.",
    "October 2010 – Participated and given the programme in the Celebration of Masks, Puppets and Picture showmen Traditons of India sponsored by <b className='bold'>Indira Gandhi Art Centre, New Delhi.</b>",
    "November 2010 – Received <b className='bold'>State Award - 2010</b> from <b className='bold'>Sri. Yediyurappa, Honourable Chief Minister of Karnataka.</b>",
    "January 2012 – visited Japan for workshop sponsored by <b className='bold'>Modern Puppet Center</b>, Kawasaki city, Japan.",
    "April 2012 – visited Paris, France along with Troupe to participate in '<b className='bold'>Festival De I'Imaginaire -2012</b>', sponsored by Maison Des Cultures Du Monde, Paris.",
    "3rd December 2012- Foundation stone laying ceremony of '<b className='bold'>Uppinakudru Yakshagana Puppet Akademy Building</b>' by Dr. P. Dayananda Pai, M.D., Century Builders Pvt. Ltd., Bangalore.",
    "March 2013   Given Yakshagana String Puppetry workshop at Madras Craft Foundation, Dakshina Chitra, Chennai.",
  ],
  features6 = [
    "July 2013 - participated in <b className='bold'>International Asian puppeteer workshop</b> and full performance held at Lida city, Japan.",
    "August 2013 - selected as a member of the <b className='bold'>Expert sub-committee on theatre and puppetry (traditional & Contemporary) of ICCR, New Delhi.",
    "November 2013- Visited Moscow, Russia for performance.</b>",
    "November 2013- Participated in <b className='bold'>Europalia Festival</b>, Brussels, Belgium.",
    "Aug. 2014- Participated in <b className='bold'>Folk Festival</b> organised by the <b className='bold'>Times of India</b> in Bangalore.",
    "March 7th 2015- Uppinakudru Puppet Akademy Building Inaugurated at Uppinakudru, sponsored by Dr. Sudhamurthy, Chair Person, Infosys Foundation, Bangalore and Dr. P. Dayananda Pai, Managing Director, M/S. Century Builders Pvt. Ltd., Bangalore",
    "April 19th 2015   Monthly programme started in Puppet Akademy through Puppet show by Sri Ganesha Yakshagana Gombeyata Mandali, Uppinakudru",
    "October 2015   Visited Hongkong for programme sponsored by Infosys Foundation, Bengaluru.",
    "April 24th 2016   Celebrated Annual Day of Puppet Akademy Building, Conferring Kogga Devanna Kamath Award 2016, Releasing Gombe Prapancha Paper and Appeal. 26th November 2016- Received Spandana Business Award 2016.",
    "3rd December 2016- Received Basti Vaman Shenoy Vishwa Konkani Seva Puraskar 2016.",
    "June 2017- Bhaskar Kogga Kamath selected as a member of Puppetry Advisory Committee in Sangeet Natak Akademy, New Delhi.",
    "Walk through of puppets towards schools - a specially arranged tour of 22 schools-From 01-09-2017 to 15-09-2017, Kumta to Kasargod. A new & specific puppet demonstration programme sponsored by Sri Nagaraj Bhat, Malpe.",
    "November 1st 2017 - Received prestagious T.M.A. Pai Award.",
    "November 2018- France Tour along with 12 Artists.",
    "February 4th 2019 - SHAMBHU HEGDE RASTRIYA NATYOSTAVA AWARD-2019.",
  ],
  features7 = [
    "Innovated new techniques of eye & mouth, lip movements.",
    "Introduction of puppet animals and birds in the puppet shows.",
    "New technique in the Stage-craft in the puppet shows.",
    "New fashioned light & shade technique introduced.",
    "Songs & narrations (interpretations) introduced in Konkani & Hindi for the respective audience.",
    "Given new dimension to Yakshagana Puppetry through practical demonstration in dance and Manipulation of the stringed Puppets.",
    `Innovated a unique trial performance of <b>YOGA BY PUPPETS</b>`,
  ],
  features8 = [
    "The Puppet of HANUMANTHA, growing to a full size, a magnificent & astonishing feat.",
    "Innovated new INDIAN PUPPET of new technique in movement of the Puppet by using rods and strings.",
    "Innovated new technique in movement and Manipulation of <b>SPIDER PUPPET</b>.",
    "Innovated <b className='bold'>new PUPPET writing puppet.</b>",
    "Innovated <b className='bold'>duck , worm, tortoise puppets etc.</b>",
    "Innovated <b className='bold'>stick dance puppet.</d>",
  ],
  features9 = [
    "Presidents Award (1966)",
    "fellowship from Sangeeth Nataka Academy of New Delhi (1976)",
    "National Award (1980)",
    "Rajyotsava Award (1993)",
    "TULSI SANMAN of Madhya Pradesh Government (1995)",
    "State Award (2010)",
  ],
  features10 = [
    "Photography",
    " Dancing",
    " Painting",
    " Cartooning and writing",
  ],
}) => {
  return (
    <>
      <Container>
        <ContentWithVerticalPadding style={{ marginTop: "120px" }}>
          <Row>
            <TextColumn>
              <Heading style={{ fontSize: "3rem" }}>
                Bhaskar Kogga Kamath
              </Heading>
              <Description>
                <b>Bhaskar Kogga Kamath</b> son of “Sri. Kogga Kamath and
                Smt.Laxmi Devi” is a graduate in arts. He is a dynamic youth,
                zealously attached to this family folk-art like his grand
                father..
              </Description>
              <Description>
                He has secured the{" "}
                <b>
                  <i>
                    Young Workers Talent Scholarship of the central government
                  </i>
                </b>{" "}
                at his tender age of 18 years for a period of three years.
              </Description>
            </TextColumn>
            <ImageColumn>
              <ImageContainer>
                <Image src={DevannaKamath} />
                {imageDecoratorBlob && <ImageDecoratorBlob />}
              </ImageContainer>
              <Offsetbackground />
            </ImageColumn>
          </Row>
        </ContentWithVerticalPadding>

        <ContentWithVerticalPadding>
          <Row>
            <TextColumn>
              <Heading style={{ fontSize: "1rem" }}>
                Awards bagged by Bhaskar Kogga Kamath for his excellence in the
                art of Puppetry, Puppet Tours and Important Events Information
              </Heading>
              <FeatureList>
                {features1.map((feature, index) => (
                  <Feature key={index}>
                    <FeatureIcon style={{ top: 6, left: -18}} />
                    <FeatureText>{renderHTML(feature)}</FeatureText>
                  </Feature>
                ))}
              </FeatureList>
            </TextColumn>
            <ImageColumn>
              <FeatureList>
                {features2.map((feature, index) => (
                  <Feature key={index}>
                    <FeatureIcon style={{ top: 6, left: -18 }} />
                    <FeatureText>{renderHTML(feature)}</FeatureText>
                  </Feature>
                ))}
              </FeatureList>
            </ImageColumn>
          </Row>
        </ContentWithVerticalPadding>

        <ContentWithVerticalPadding>
          <Row>
            <TextColumn style={{ maxWidth: "51rem" }}>
              <FeatureList>
                {features3.map((feature, index) => (
                  <Feature key={index}>
                    <FeatureIcon style={{ top: 6, left: -18 }} />
                    <FeatureText>{renderHTML(feature)}</FeatureText>
                  </Feature>
                ))}
              </FeatureList>
            </TextColumn>
            <ImageColumn>
              <ImageContainer>
                <Image src={DevannaKamath2} />
                {imageDecoratorBlob && <ImageDecoratorBlob />}
              </ImageContainer>
              <Offsetbackground />
            </ImageColumn>
          </Row>
        </ContentWithVerticalPadding>

        <ContentWithVerticalPadding>
          <Row>
            <TextColumn>
              <FeatureList>
                {features4.map((feature, index) => (
                  <Feature key={index}>
                    <FeatureIcon style={{ top: 6, left: -18 }} />
                    <FeatureText>{renderHTML(feature)}</FeatureText>
                  </Feature>
                ))}
              </FeatureList>
            </TextColumn>
            <ImageColumn>
              <FeatureList>
                {features5.map((feature, index) => (
                  <Feature key={index}>
                    <FeatureIcon style={{ top: 6, left: -18 }} />
                    <FeatureText>{renderHTML(feature)}</FeatureText>
                  </Feature>
                ))}
              </FeatureList>
            </ImageColumn>
          </Row>
        </ContentWithVerticalPadding>

        <ContentWithVerticalPadding>
          <Row>
            <ImageColumn>
              <ImageContainer>
                <Image
                  src={DevannaKamath4}
                  style={{
                    borderRadius: "0",
                    borderTopLeftRadius: "0",
                    borderTopRightRadius: "0",
                  }}
                />
                <Image
                  src={DevannaKamath5}
                  style={{
                    borderRadius: "0",
                    borderTopLeftRadius: "0",
                    borderTopRightRadius: "0",
                  }}
                />
                {imageDecoratorBlob && <ImageDecoratorBlob />}
              </ImageContainer>
              <Offsetbackground />
            </ImageColumn>
            <TextColumn style={{ maxWidth: "51rem" }}>
              <FeatureList>
                {features6.map((feature, index) => (
                  <Feature key={index}>
                    <FeatureIcon style={{ top: 6, left: -18 }} />
                    <FeatureText>{renderHTML(feature)}</FeatureText>
                  </Feature>
                ))}
              </FeatureList>
            </TextColumn>
          </Row>
        </ContentWithVerticalPadding>

        <ContentWithVerticalPadding>
          <Row>
            <TextColumn>
              <Heading style={{ fontSize: "1rem" }}>Research Works</Heading>
              <FeatureList>
                {features7.map((feature, index) => (
                  <Feature key={index}>
                    <FeatureIcon style={{ top: 6, left: -18 }} />
                    <FeatureText>{renderHTML(feature)}</FeatureText>
                  </Feature>
                ))}
              </FeatureList>
            </TextColumn>
            <ImageColumn>
              <FeatureList>
                {features8.map((feature, index) => (
                  <Feature key={index}>
                    <FeatureIcon style={{ top: 6, left: -18 }} />
                    <FeatureText>{renderHTML(feature)}</FeatureText>
                  </Feature>
                ))}
              </FeatureList>
            </ImageColumn>
          </Row>
        </ContentWithVerticalPadding>

        <ContentWithVerticalPadding>
          <Row>
            <TextColumn>
              <Heading style={{ fontSize: "1rem" }}>Hobbies</Heading>
              <FeatureList>
                {features10.map((feature, index) => (
                  <Feature key={index}>
                    <FeatureIcon style={{ top: 6, left: -18 }} />
                    <FeatureText>{renderHTML(feature)}</FeatureText>
                  </Feature>
                ))}
              </FeatureList>
            </TextColumn>
            <ImageColumn>
              <Heading style={{ fontSize: "1rem" }}>Awards</Heading>
              <FeatureList>
                {features9.map((feature, index) => (
                  <Feature key={index}>
                    <FeatureIcon style={{ top: 6, left: -18 }} />
                    <FeatureText>{renderHTML(feature)}</FeatureText>
                  </Feature>
                ))}
              </FeatureList>
            </ImageColumn>
          </Row>
        </ContentWithVerticalPadding>
      </Container>
    </>
  );
};
