import image1 from "./In News/IMG_3770.JPG";
import image2 from "./In News/IMG_20200417_185927.jpg";
import image3 from "./In News/IMG_20200417_185909.jpg";
import image4 from "./In News/IMG_20200417_185813.jpg";
import image5 from "./In News/IMG_20200417_185749.jpg";
import image6 from "./In News/IMG_20200417_185720.jpg";
import image7 from "./In News/IMG_20200417_185653.jpg";
import image8 from "./In News/IMG_20200417_185624.jpg";
import image9 from "./In News/IMG_20200417_185546.jpg";
import image10 from "./In News/IMG_20200417_185518.jpg";
import image11 from "./In News/IMG_20200417_185456.jpg";
import image12 from "./In News/IMG_20170830_190221.jpg";
import image13 from "./In News/Gombeatta 28-6-2018.jpg";

export const photos = [
  {
    src: image1,
    width: 4,
    height: 3,
  },
  {
    src: image2,
    width: 4,
    height: 3,
  },
  {
    src: image3,
    width: 4,
    height: 3,
  },
  {
    src: image4,
    width: 4,
    height: 3,
  },
  {
    src: image5,
    width: 4,
    height: 3,
  },
  {
    src: image6,
    width: 4,
    height: 3,
  },
  {
    src: image7,
    width: 4,
    height: 3,
  },
  {
    src: image8,
    width: 4,
    height: 3,
  },
  {
    src: image9,
    width: 4,
    height: 3,
  },
  {
    src: image10,
    width: 4,
    height: 3,
  },
  {
    src: image11,
    width: 4,
    height: 3,
  },
  {
    src: image12,
    width: 4,
    height: 3,
  },
  {
    src: image13,
    width: 4,
    height: 3,
  },
];
