import "tailwindcss/dist/base.css";
import "styles/globalStyles.css";
import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

import HomePage from "./pages/HomePage";
import History from "./pages/History";
import NavBar from "./components/headers/light";
// import Footer from "./components/footers/FiveColumnWithBackground";
import Footer from "./components/footers/MiniCenteredFooter";

import FamilyBackground from "./pages/about-us/FamilyBackground";
import BhaskarKamath from "./pages/about-us/BhaskarKamath";
import DevannaKamath from "./pages/about-us/DevannaKamath";
import TheNews from "./pages/about-us/TheNews";
import OurJourney from "./pages/about-us/OurJourney";

import YakshaganaStringPuppets from "./pages/puppets/YakshaganaStringPuppets";
import PuppetRequirements from "./pages/puppets/Requirements";
import StagePhotos from "./pages/puppets/StagePhotos";
import PerformancePhotos from "./pages/puppets/PerformancePhotos";
import PerformanceVideos from "./pages/puppets/PerformanceVideos";

import DemoDetailsRequirements from "./pages/demo-details/Requirements";
import DemoPhotos from "./pages/demo-details/DemoPhotos";
import DemoVideos from "./pages/demo-details/DemoVideos";

import ProgramsHeld from "./pages/academy/ProgramsHeld";
import KoggaDevannaKamathAward from "./pages/academy/KoggaDevannaKamathAward";
import Museum from "./pages/academy/Museum";
import AcademyProgramPhotos from "./pages/academy/AcademyProgramPhotos";
import AcademyProgramVideos from "./pages/academy/AcademyProgramVideos";

import GalleryAwards from "./pages/gallery/GalleryAwards";
import GalleryForeignTours from "./pages/gallery/GalleryForeignTours";
import GalleryPuppets from "./pages/gallery/GalleryPuppets";
import GalleryOthers from "./pages/gallery/GalleryOthers";

import AppealToPublic from "./pages/AppealToPublic";
import ContactUs from "./pages/ContactUs";

export default function App() {
  return (
    <Router>
      <NavBar />

      <Switch>
        <Route exact path="/">
          <HomePage />
        </Route>
        <Route exact path="/history">
          <History />
        </Route>

        <Route exact path="/family-background">
          <FamilyBackground />
        </Route>
        <Route exact path="/family-background">
          <FamilyBackground />
        </Route>
        <Route exact path="/kogga-devanna-kamath">
          <DevannaKamath />
        </Route>
        <Route exact path="/bhaskar-kogga-kamath">
          <BhaskarKamath />
        </Route>
        <Route exact path="/about-us-our-journey">
          <OurJourney />
        </Route>
        <Route exact path="/about-us-in-the-news">
          <TheNews />
        </Route>

        <Route exact path="/puppets-yakshagana-string-puppets">
          <YakshaganaStringPuppets />
        </Route>
        <Route exact path="/puppets-Requirements">
          <PuppetRequirements />
        </Route>
        <Route exact path="/puppets-stage-photos">
          <StagePhotos />
        </Route>
        <Route exact path="/puppets-performance-photos">
          <PerformancePhotos />
        </Route>
        <Route exact path="/puppets-performance-videos">
          <PerformanceVideos />
        </Route>

        <Route exact path="/demo-details-requirements">
          <DemoDetailsRequirements />
        </Route>
        <Route exact path="/demo-details-demo-photos">
          <DemoPhotos />
        </Route>
        <Route exact path="/demo-details-demo-videos">
          <DemoVideos />
        </Route>

        <Route exact path="/about-akademy">
          <ProgramsHeld />
        </Route>
        <Route exact path="/kogga-devanna-kamath-award">
          <KoggaDevannaKamathAward />
        </Route>
        <Route exact path="/museum">
          <Museum />
        </Route>
        <Route exact path="/akademy-programme-photos">
          <AcademyProgramPhotos />
        </Route>
        <Route exact path="/akademy-programme-videos">
          <AcademyProgramVideos />
        </Route>

        <Route exact path="/gallery-awards">
          <GalleryAwards />
        </Route>
        <Route exact path="/gallery-foreign-tours">
          <GalleryForeignTours />
        </Route>
        <Route exact path="/gallery-puppets">
          <GalleryPuppets />
        </Route>
        <Route exact path="/gallery-others">
          <GalleryOthers />
        </Route>

        <Route exact path="/appeal-to-public">
          <AppealToPublic />
        </Route>
        <Route exact path="/contact-us">
          <ContactUs />
        </Route>
      </Switch>

      <Footer />
    </Router>
  );
}
