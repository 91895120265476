import React from "react";
import ImageGallery from "react-image-gallery";
import styled from "styled-components";
import YakshaganaStringPuppetsSection from "../../components/forms/TwoColContactUsWithIllustration";

import image1 from "../../yakshagana/Puppets/02_Requirements/02.Pics of Instruments Used/01.Tala.JPG";
import image2 from "../../yakshagana/Puppets/02_Requirements/02.Pics of Instruments Used/ANKLE BELT (Gejje)-ink.jpeg";
import image3 from "../../yakshagana/Puppets/02_Requirements/02.Pics of Instruments Used/ANKLE BELT - (Gejje)-ink.jpeg";
import image4 from "../../yakshagana/Puppets/02_Requirements/02.Pics of Instruments Used/02.1 Harmonium.JPG";
import image5 from "../../yakshagana/Puppets/02_Requirements/02.Pics of Instruments Used/03.0.High and Low pitch IndianDrum Chande.JPG";
import image6 from "../../yakshagana/Puppets/02_Requirements/02.Pics of Instruments Used/04.High and Low pitch Maddale.JPG";

import image7 from "../../yakshagana/Puppets/02_Requirements/02.Pics of Instruments Used/05.Connection Box.JPG";
import image8 from "../../yakshagana/Puppets/02_Requirements/02.Pics of Instruments Used/CONCH-ink.jpeg";
import image9 from "../../yakshagana/Puppets/02_Requirements/02.Pics of Instruments Used/GONG-ink-min.jpeg";
import image10 from "../../yakshagana/Puppets/02_Requirements/02.Pics of Instruments Used/06.Traditional Bell.JPG";

const images = [
  {
    original: image1,
    thumbnail: image1,
    description: "TALA",
  },
  {
    original: image4,
    thumbnail: image4,
    description: "HARMONIUM",
  },
  {
    original: image5,
    thumbnail: image5,
    description: "HIGH AND LOW PITCH INDIAN DRUM (CHANDE)",
  },
  {
    original: image6,
    thumbnail: image6,
    description: "HIGH AND LOW PITCH MADDALE",
  },
  {
    original: image9,
    thumbnail: image9,
    description: "GONG",
  },
  {
    original: image8,
    thumbnail: image8,
    description: "CONCH",
  },
  {
    original: image10,
    thumbnail: image10,
    description: "Traditional Bell",
  },
  {
    original: image2,
    thumbnail: image2,
    description: "ANKLE BELT (Gejje)",
  },
  {
    original: image3,
    thumbnail: image3,
    description: "ANKLE BELT (Gejje)",
  },

  {
    original: image7,
    thumbnail: image7,
    description: "SWITCH BOARD",
  },
];
const SliderMain = styled.div`
  margin-bottom: 50px;
  margin-left: calc(100% - 94vw);
  padding: 100px;
  padding-top: 0;
  @media (min-width: 320px) and (max-width: 480px) {
    padding: 0;
  }
  .image-gallery-slide-wrapper {
    background: black !important;
  }
  h1 {
    font-size: 24px;
    font-weight: bold;
  }
  h2 {
    font-size: 20px;
    font-weight: bold;
  }
  ul {
    margin: 20px 0 20px 50px;
    list-style: decimal-leading-zero;

    li {
      padding: 5px;
    }
  }
`;

export default function Requirements() {
  return (
    <div>
      <YakshaganaStringPuppetsSection
        title={"PUPPET SHOW REQUIREMENTS"}
        image1={null}
      />
      <SliderMain>
        <br />
        <h2>STAGE</h2>
        <ul>
          <li>
            About 30 feet x 30 feet stage covered on all the three sides (if it
            is not a hall) and open only to the Audience.
          </li>
          <li>
            Wooden Platform or tables measuring size of 15 feet x 10 feet and
            2.5 feet height,covered by thick carpet on which we create our own
            stage for our puppet shows.
          </li>
          <li>
            We have the necessary lighting equipments but you should arrange for
            the necessary Electricity connection.
          </li>
          <li>Required 6 good quality Standing Microphones and monitor.</li>
          <li>
            During performance there should be a complete darkness on the
            Audience side(like a Cinema theatre) .
          </li>
          <li>
            As per traditional norms we use LIVE Music.
            <ul>
              <li>TALA</li>
              <li>HARMONIUM</li>
              <li>HIGH AND LOW PITCH INDIAN DRUM (CHANDE)</li>
              <li>HIGH AND LOW PITCH MADDALE</li>
              <li>GONG</li>
              <li>CONCH</li>
              <li>TRADITIONAL BELL</li>
              <li>ANKLE BELT (Gejje)</li>
            </ul>
          </li>
        </ul>
        <br />
        <h2>PREPARATION TIMIMG</h2>
        <p>
          Need one hour for the prearrangements before the actual show and one
          hour to packup after the show depends on place and stage.
        </p>
        <br />
        <h2>STORY</h2>
        <ul>
          <li>CHOODAMANI LANKADAHANA (PART OF RAMAYANA)</li>
          <li>NARAKASURAVADHE GARUDA GARVA BHANGA( PART OF MAHABHARATHA)</li>
          <li>SRI DEVI MAHATME (PART OF BHAGAVATHA)</li>
        </ul>
        <br />
        <h2>DURATION OF THE SHOW</h2>
        <p>1 HOUR (ONE HOUR)</p>
        <br />
        <h2>LANGUAGE</h2>
        <p>KANNADA</p>
        <br />
        <h2>NUMBER OF ARTISTS</h2>
        <ul>
          <li>15 IN INDIA</li>
          <li>12 IN ABROAD</li>
        </ul>
        <br />
        <br />
        <ImageGallery items={images} />
      </SliderMain>
    </div>
  );
}
