import image1 from "./demo_stage/01Demo Photo-ink-min.jpeg";
import image2 from "./demo_stage/02Demo Photo-ink-min.jpeg";
import image3 from "./demo_stage/03Demo Photo-ink-min.jpeg";
import image4 from "./demo_stage/04Demo Photo-ink.jpeg";
import image5 from "./demo_stage/05Demo Photo-ink.jpeg";
import image6 from "./demo_stage/06Demo Photo-ink.jpeg";
import image7 from "./demo_stage/07Demo Photo-ink.jpeg";
import image8 from "./demo_stage/08Demo Photo-ink.jpeg";
import image10 from "./demo_stage/10Demo Photo-ink-min.jpeg";
import image11 from "./demo_stage/11Demo Photo-ink-min.jpeg";
import image12 from "./demo_stage/12Demo Photo-ink-min.jpeg";
import image13 from "./demo_stage/13Demo Photo-ink-min.jpeg";
import image14 from "./demo_stage/14Demo Photo-ink-min.jpeg";
import image15 from "./demo_stage/15Demo Photo-ink-min.jpeg";
import image16 from "./demo_stage/16Demo Photo-ink-min.jpeg";
import image18 from "./demo_stage/17Demo Photo-ink-min.jpeg";
import image17 from "./demo_stage/18Demo Photo-ink.jpeg";
import image19 from "./demo_stage/19Demo Photo-ink.jpeg";

export const photos = [
  {
    src: image1,
  },

  {
    src: image2,
  },

  {
    src: image3,
  },

  {
    src: image4,
  },

  {
    src: image5,
  },

  {
    src: image6,
  },

  {
    src: image7,
  },

  {
    src: image8,
  },

  {
    src: image10,
  },

  {
    src: image11,
  },

  {
    src: image12,
  },

  {
    src: image13,
  },

  {
    src: image14,
  },

  {
    src: image15,
  },

  {
    src: image16,
  },

  {
    src: image17,
  },

  {
    src: image18,
  },

  {
    src: image19,
  },
];
