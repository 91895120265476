import image1 from "./Puppet Academy/04. Akademy Program Photos/IMG_7952.JPG";
import image2 from "./Puppet Academy/04. Akademy Program Photos/IMG_6804.JPG";
import image3 from "./Puppet Academy/04. Akademy Program Photos/IMG_6252.JPG";
import image4 from "./Puppet Academy/04. Akademy Program Photos/IMG_5971.JPG";
import image5 from "./Puppet Academy/04. Akademy Program Photos/IMG_5028.JPG";
import image6 from "./Puppet Academy/04. Akademy Program Photos/IMG_3365.JPG";
import image7 from "./Puppet Academy/04. Akademy Program Photos/IMG_2750.JPG";
import image8 from "./Puppet Academy/04. Akademy Program Photos/DSC04983.JPG";
import image9 from "./Puppet Academy/04. Akademy Program Photos/06.Akd.Prog.jpg";
import image10 from "./Puppet Academy/04. Akademy Program Photos/02.Aka.Prog.JPG";
import image11 from "./Puppet Academy/04. Akademy Program Photos/05.Akad.Program.JPG";
import image12 from "./Puppet Academy/04. Akademy Program Photos/03.Akademy Prog.JPG";
import image13 from "./Puppet Academy/04. Akademy Program Photos/IMG20200307161944.jpg";
import image14 from "./Puppet Academy/04. Akademy Program Photos/IMG20191115154947.jpg";
import image15 from "./Puppet Academy/04. Akademy Program Photos/04.Akd.Prog Photo.JPG";
import image16 from "./Puppet Academy/04. Akademy Program Photos/02.Akad.Prog.Photo.jpg";
import image17 from "./Puppet Academy/04. Akademy Program Photos/01 Academy Prog Photos.JPG";

export const photos = [
  {
    src: image1,
  },

  {
    src: image2,
  },

  {
    src: image3,
  },

  {
    src: image4,
  },

  {
    src: image5,
  },

  {
    src: image6,
  },

  {
    src: image7,
  },

  {
    src: image8,
  },
  {
    src: image9,
  },

  {
    src: image10,
  },

  {
    src: image11,
  },

  {
    src: image12,
  },

  {
    src: image13,
  },

  {
    src: image14,
  },

  {
    src: image15,
  },

  {
    src: image16,
  },

  {
    src: image17,
  },
];
