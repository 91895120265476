import React from "react";
import ImageGallery from "react-image-gallery";
import styled from "styled-components";
import YakshaganaStringPuppetsSection from "../../components/forms/TwoColContactUsWithIllustration";

import image3 from "./Puppet Academy/01. Monthly Programs Held/IMG_0300.JPG";
import image1 from "./Puppet Academy/01. Monthly Programs Held/IMG-20200711-WA0086-ink.jpeg";
import image2 from "./Puppet Academy/01. Monthly Programs Held/Stage.JPG";
import image4 from "./Puppet Academy/01. Monthly Programs Held/Sudhamurthy- P.2.JPG";
import image5 from "./Puppet Academy/01. Monthly Programs Held/Dayananda Pai.jpg";
import photo from "./Puppet Academy/01. Monthly Programs Held/IMG_8604-ink-min.jpeg";

const SliderMain = styled.div`
  margin-bottom: 50px;
  margin-left: 100px;
  padding: 100px;
  padding-top: 0;
  @media (min-width: 320px) and (max-width: 480px) {
    padding: 10px;
    margin-left: 0;
  }
  .image-gallery-slide-wrapper {
    background: black !important;
  }
  h1 {
    font-size: 24px;
    font-weight: bold;
  }
  ul {
    margin: 20px 0 20px 50px;
    list-style: decimal-leading-zero;
    /* list-style-type: none; */
    /* counter-reset: item; */

    li {
      padding: 5px;
      /* counter-increment: item; */
      /* &:before {
        display: inline-block;
        width: 1.5em;
        padding-right: 0.5em;
        font-weight: bold;
        text-align: right;
        content: counter(item) ".";
      } */
    }
  }
`;

const images = [
  {
    original: image1,
    thumbnail: image1,
    description: "Puppet Akademy Building",
  },
  {
    original: image2,
    thumbnail: image2,
    description: "Kogga Devanna Kamath Theater",
  },
  {
    original: image3,
    thumbnail: image3,
    description: "Seating arrangements in Theater",
  },

  {
    original: image4,
    thumbnail: image4,
    description: "We are deeply indebted to Dr.Sudha Murthy",
  },
  {
    original: image5,
    thumbnail: image5,
    description: "We are deeply indebted to Dr.P.Dayananda Pai",
  },
];

export default function ProgramsHeld() {
  return (
    <div>
      <YakshaganaStringPuppetsSection title={"About Akademy"} image1={null} />
      <SliderMain>
        <img src={photo} alt="photo" />
        <br />
        <p>
          We are proud to introduce two great personalities of our country one
          being Dr. Sudhamurthy, Chair person, Infosys Foundation, Bengaluru and
          another Dr. P. Dayananda Pai, eminent industrialist, Bengaluru . With
          the help and guidance from these two well known personalities, a dream
          of our ancestors has become true. A well built huge “PUPPET AKADEMY
          BUILDING” with an estimated cost of Rs. One Crore has come up in our
          Uppinakudru, a small coastal village of Karnataka giving opportunity
          to exhibit not only puppetry programmes but also to different fields
          of literary and other art activities. This was inaugurated on 7 th
          March of 2015 and we are now in the 5 th year of its inception. Every
          month we have one or the other programme by the people local or
          neighbouring areas giving opportunity to expose their talents and we
          are happy to record that many of such programmes were over crowded
          beyond our expectation. We have already arranged 61 monthly programmes
          one each month so far which was possible by their over whelming
          encouragement.
        </p>
        <br />
        <h1>
          61 Monthly programmes held in Uppinakudru Puppet Academy in Five years
        </h1>
        <ul>
          <li>07-03-2015 - Inauguration of Uppinakudru Puppet Akademy.</li>
          <li>
            19-04-2015 - choodamani Lanka Dahana Puppet show by Sri Ganesha
            Yakshagana Gombeyata Mandali, Uppinakudru.
          </li>
          <li>24-05-2015 – Light Music by Sri Ganesha Gangolli.</li>
          <li>
            14-06-2015 - Yakshagana Gana Vaibhava by Sri Herenjalu Gopala Ganiga
            & Team.
          </li>
          <li>
            26-07-2015 - Yakshagana Tala Maddale by Sri Herenjalu Gopala Ganiga
            & Team.
          </li>
          <li>
            15-08-2015 - Cultural programmes by the students of Uppinakudru
            schools on the occasion Of Independence Day Celebration.
          </li>
          <li>
            13-09-2015 - Yakshagana by Sri Durgaparameshwari Girl’s Yakshagana
            Troupe, Hemmadi.
          </li>
          <li>18-10-2015 - Yakshagana by Sri Sai Kala Prathistana, Shimoga.</li>
          <li>15-11-2015 - Yakshagana play by Saligrama Childrens’ Troupe</li>
          <li>
            20-12-2015 - Yakshagana Bayalata by the students of Uppinakudru
            Puppet Akademy.
          </li>
          <li>
            15-01-2016 - Yakshagana Gombeyata by Sri Gopalakrishna Yakshagana
            Bembeyata Sangha Kasaragod.
          </li>
          <li>
            14-02-2016 - Yakshagana by the members of Namma Kala Kendra,
            Koteshwara.
          </li>
          <li>13-03-2016 - Yakshagana by Hangarkatte Kala Kendra.</li>
          <li>
            24-04-2016 - 1st Annual Day Celebration of Uppinakudru Puppet
            Akademy.
          </li>
          <li>
            15-05-2016 - Tribute to Sitar Player Sri Avinash Hebbar, Kundapura{" "}
          </li>
          <li>19-06-2016 - Folk and Light music by Sri Ganesh Gangolli.</li>
          <li>24-07-2016 - Bhajan by Smt. Pramila Kundapura.</li>
          <li>
            15-08-2016 - Cultural programmes by the students of Uppinakudru
            schools on the occasion Of Independence Day Celebration.
          </li>
          <li>
            18-09-2016-Light music by the members of Sri Annapurneshwari Blind
            Geeta Gayana Sangha, Shirasi.
          </li>
          <li>
            16-10-2016 - Yakshagana Tala Maddale by Sri Ranjeet Kumar Shetty,
            Vakwadi & Team.
          </li>
          <li>
            27-11-2016 - Mono Yakshagana play by Sri Mantapa Prabhakar Upadhya,
            Bengaluru.
          </li>
          <li>
            18-12-2016 - Bhajan by Sri Laxmi Venkatesh Bhajana Mandali,
            Kundapura.
          </li>
          <li>
            15-01-2017 - Kala Kusumanjali by Stuthi Event Management, Kundapura.
          </li>
          <li>12-02-2017 - Gindi Dance by Sri Sathish Nayak, Guddeangady.</li>
          <li>26-03-2017 - Harmonium Solo by Sri Sudheer Nayak, Mumbai.</li>
          <li>
            16-04-2017 - 2nd Annual Day Celebration of Uppinakudru Puppet
            Akademy.
          </li>
          <li>
            07-05-2017 - Bhajan& Devotional Songs by Vidwan Sri Vageesh Bhat,
            Bengaluru.
          </li>
          <li>18-06-2017 - Ganasudha by Sri Prakash Shenoy & team Gangolli.</li>
          <li>
            16-07-2017 - Bhajan Sandhya by Sri Siddi Vinayaka Mahila Bhajan
            Sangha, Kundapura.
          </li>
          <li>
            15-08-2017 - Cultural programmes by the students of Uppinakudru
            schools on the occasion Of Independence Day Celebration
          </li>
          <li>
            17-09-2017 - Konkani Rasamanjari by Smt. Narmada Prabhu, Tekkatte.
          </li>
          <li>
            15-10-2017 - Bhajan by Tallur Vittal Temple Bhajana team & Sri
            Sathish Nayak Guddeangady & team.
          </li>
          <li>
            19-11-2017 - Harikatha Satsangha by Haridas Sri B.C.Rao Shivapura.
          </li>
          <li>
            17-12-2017 - Hindusthani Classical Music by Sadhana Kala Sangama,
            Kundapura.
          </li>
          <li>
            21-01-2018 - Bhajan Sandhya by Sri Laxmi Venkatesh Bala Bhajana
            Mandali, Pete Sri Venkataramana Temple, Kundapura.
          </li>
          <li>
            18-02-2018 - Devotional Songs by Smt. Madhushree S.Pai, Koteshwara.
          </li>
          <li>
            18-03-2018 - Light Music by Kum. Kruthika Shenoy K. Kundapura.
          </li>
          <li>
            29-04-2018 - 3rd Annual Day Celebration of Uppinakudru Puppet
            Akademy.
          </li>
          <li>
            20-05-2018 - Karnataka Sangeetha & Light Music by Smt. Nalini
            Venkatramana Rao.
          </li>
          <li>
            17-06-2018 - Devotional songs by Sri K. Ramesh Pai, Kundapura.
          </li>
          <li>
            15-07-2018 - Kunitha Bhajan by Sri Mani Siddalingeshwara Bhajana
            Mandali, Movadi.
          </li>
          <li>
            15-08-2018 - Cultural programmes by the students of Uppinakudru
            schools on the occasion Of Independence Day Celebration.
          </li>
          <li>
            23-09-2018 - Light Music by the students of Sadhana Kala Sangama,
            Kundapura.
          </li>
          <li>
            21-10-2018 - Bharathanatyam by Dr. Malavika Hebbar, Kundapura.
          </li>
          <li>
            04-11-2018 - Swara Sandhya by Kum. Shraddha Om Ganesh, Uppunda.
          </li>
          <li>09-12-2018 - Karnatic Light Music by Kum. Poorvi Chathra.</li>
          <li>20-01-2019 - Dasa Keerthanas by Smt. Pooja Bhat, Chempi.</li>
          <li>17-02-2019-Devotional Songs by Master Rakshan.</li>
          <li>10-03-2019 - Hindusthani Classical Music by Kum. Anusha Bhat.</li>
          <li>
            07-04-2019 - 4th Annual Day Celebration and 50th programme of
            Uppinakudru Puppet Akademy.
          </li>
          <li>
            19-05-2019-Inauguration of “ Walkthrough of Students towards Puppet
            House” - Cultural programmes by the students of New Aided Higher
            Primary School, Bantwadi and Anganawadi students of Uppinakudru.
          </li>
          <li>
            29-06-2019 - Cultural programmes by Narayana Special Children’s
            School, Tallur and Govt. Higher Primary School. Tallur.
          </li>
          <li>
            20-07-2019-Programmes by the students of B.R.Rao’s Hindu Higher
            Primary School, Kundapura.
          </li>
          <li>
            15-08-2019 - Cultural programmes by the students of Uppinakudru
            schools on the occasion Of Independence Day Celebration.
          </li>
          <li>
            28-09-2019 - Various cultural programmes by the students of Govt.
            High School, Hakladi.
          </li>
          <li>
            10-10-2019 - Programmes by the students of Sri Venkatramana English
            Medium School, Kundapura.
          </li>

          <li>
            15-11-2019 - Programmes by the students of Sri Siddhi Vinayaka
            Residential School,Hattiangady.
          </li>
          <li>
            16-12-2019 - Chinnara Kalarava by Uppinakudru three Anganavadi kids.
          </li>
          <li>
            18-01-2020 - Cultural programmes by the students of Govt. Higher
            Primary School, Gujjadi.
          </li>
          <li>
            22-02-2020 - Programmes by the students of Govt. Higher Primary
            School, Maravanthe.
          </li>
          <li>
            07-03-2020 - Various cultural programmes by the students of Sri
            Saraswathi Vidyalaya English Medium School, Gangolli.
          </li>
        </ul>

        <ImageGallery items={images} />
      </SliderMain>
    </div>
  );
}
