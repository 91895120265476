import React from "react";
import styled from "styled-components";
import MapSection from "../components/faqs/SimpleWithSideImage";
import "../../node_modules/react-image-gallery/styles/css/image-gallery.css";
import ImageGallery from "react-image-gallery";
import image1 from "./about-us/photos/01.Devanna Padmanabha Kamath/03. Devanna Kamath With Puppet Team.jpg";
import image2 from "./about-us/photos/01.Devanna Padmanabha Kamath/04.Ganesha Aradhane.jpg";
import image3 from "./about-us/photos/01.Devanna Padmanabha Kamath/scan0369.jpg";

const images = [
  {
    original: image1,
    thumbnail: image1,
    description:
      "Troupe Members of Devanna Padmanabha Kamath, National awardee 1966",
  },
  {
    original: image2,
    thumbnail: image2,
    description: "Ganesha Pooja",
  },
  {
    original: image3,
    thumbnail: image3,
    description: "Puppet Performance",
  },
];

const HistoryDiv = styled.div`
  padding: 40px;
  box-sizing: border-box;
`;
export default function History() {
  return (
    <HistoryDiv>
      <MapSection />
      <ImageGallery style={{ width: "400px" }} items={images} />
    </HistoryDiv>
  );
}
