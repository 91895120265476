import image1 from "./Gallary/03.Foreign Tour Pics/01.Foreign Tour.JPG";
import image2 from "./Gallary/03.Foreign Tour Pics/02.Foreign Tour.JPG";
import image3 from "./Gallary/03.Foreign Tour Pics/03.Foreign Tour.JPG";
import image4 from "./Gallary/03.Foreign Tour Pics/04.Foreign Tour.JPG";
import image5 from "./Gallary/03.Foreign Tour Pics/05.Foreign tour.JPG";
import image6 from "./Gallary/03.Foreign Tour Pics/06.Japan Tour.JPG";
import image7 from "./Gallary/03.Foreign Tour Pics/07.Foreign Tour.jpg";
import image8 from "./Gallary/03.Foreign Tour Pics/08.Foreign Tour.jpg";
import image9 from "./Gallary/03.Foreign Tour Pics/09.Foreign Tour.jpg";
import image10 from "./Gallary/03.Foreign Tour Pics/10.Foreign Tour.jpg";
import image11 from "./Gallary/03.Foreign Tour Pics/11.Foreign Tour.JPG";
import image12 from "./Gallary/03.Foreign Tour Pics/12.Foreign Tour.JPG";
import image13 from "./Gallary/03.Foreign Tour Pics/13.Foreign Tour.JPG";
import image14 from "./Gallary/03.Foreign Tour Pics/13.London Tour.jpg";
import image15 from "./Gallary/03.Foreign Tour Pics/DSC_0248.JPG";
import image16 from "./Gallary/03.Foreign Tour Pics/DSC_0239.JPG";
import image17 from "./Gallary/03.Foreign Tour Pics/DSC_0235.JPG";

export const photos = [
  {
    src: image1,
  },

  {
    src: image2,
  },

  {
    src: image3,
  },

  {
    src: image4,
  },

  {
    src: image5,
  },

  {
    src: image6,
  },

  {
    src: image7,
  },

  {
    src: image8,
  },
  {
    src: image9,
  },

  {
    src: image10,
  },
  {
    src: image11,
  },

  {
    src: image12,
  },

  {
    src: image13,
  },

  {
    src: image14,
  },

  {
    src: image15,
  },

  {
    src: image16,
  },

  {
    src: image17,
  },
];
