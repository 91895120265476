import React from "react";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
import {
  SectionHeading,
  Subheading as SubheadingBase,
} from "components/misc/Headings.js";
import { PrimaryButton as PrimaryButtonBase } from "components/misc/Buttons.js";
import EmailIllustrationSrc from "images/email-illustration.svg";
import ImageRight from "../../pages/about-us/photos/DevannaKamath/Bhaksar Kogga Kamath.jpg";

const Container = tw.div`relative`;
const TwoColumn = tw.div`flex flex-col md:flex-row justify-between max-w-screen-xl mx-auto`;
const Column = tw.div`w-full max-w-md mx-auto md:max-w-none md:mx-0`;
const ImageColumn = tw(Column)`md:w-5/12 flex-shrink-0 h-80 md:h-auto`;
const TextColumn = styled(Column)((props) => [
  tw`md:w-7/12 mt-16 md:mt-0`,
  props.textOnLeft
    ? tw`md:mr-12 lg:mr-16 md:order-first`
    : tw`md:ml-12 lg:ml-16 md:order-last`,
]);

const Image = styled.div((props) => [
  `background-image: url("${props.imageSrc}");`,
  tw`rounded bg-contain bg-no-repeat bg-center h-full`,
]);
const TextContent = tw.div`lg:py-8 text-center md:text-left`;

const Subheading = tw(SubheadingBase)`text-center md:text-left`;
const Heading = tw(
  SectionHeading
)`mt-4 font-black text-left text-3xl sm:text-4xl lg:text-5xl text-center md:text-left leading-tight`;
const Description = tw.p`mt-4 text-center md:text-left text-sm md:text-base lg:text-lg font-medium leading-relaxed text-secondary-100`;

const Form = tw.form`mt-8 md:mt-10 text-sm flex flex-col lg:flex-row`;
const Input = tw.input`border-2 px-5 py-3 rounded focus:outline-none font-medium transition duration-300 hocus:border-primary-500`;

const SubmitButton = tw(PrimaryButtonBase)`inline-block lg:ml-6 mt-6 lg:mt-0`;

export default ({
  subheading = "",
  description = "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",
  submitButtonText = "Contact Me",
  formAction = "#",
  formMethod = "get",
  textOnLeft = true,
  image1,
  title,
}) => {
  // The textOnLeft boolean prop can be used to display either the text on left or right side of the image.

  return (
    <Container>
      <TwoColumn>
        {image1 && (
          <ImageColumn>
            <Image imageSrc={image1} />
          </ImageColumn>
        )}

        <TextColumn textOnLeft={textOnLeft}>
          <TextContent>
            <Heading style={{ fontSize: "2rem" }}>
              <>
                <span tw="text-primary-500">{title}</span>
                <wbr />
              </>
            </Heading>
            {image1 && (
              <>
                <Description>
                  The string puppet play of Karnataka (Yakshagana style of
                  coastal area) is interesting, both on account of its technique
                  and content – the presentation is highly stylized since it has
                  to adhere strictly to the norms and standards of Yakshagana
                  Bayalata, one of the most remarkable among the numerous arts
                  of Karnataka. All the ritualistic rigor of the Yakshagana
                  ‘Human Theatre’ has to be observed in its original form and
                  adept manipulators of the puppets seem cabable of making the
                  yakshagana puppets leap to life. At times it is difficult to
                  tell whether what one is witnessing is a mere show of wooden
                  puppets, inert and lifeless, or a serious performance by
                  well-trained men and women, pouring forth in dance, song and
                  dialogue the whole range of human emotions and passions.
                </Description>
                <Description>
                  These wooden puppets are about 18 inches high. Their costumes
                  are exactly like those worn by the charaters from yakshagana
                  bayalata, with the same elaborate make-up, high and colourful
                  Head gear and Heavy jewellery.
                </Description>
                <Description>
                  The person who infuses life into the puppet and makes it come
                  alive, by dextrous manipulation, is the puppet master, known
                  as the ‘Suthradhara’. The content in the Yakshagana puppetry,
                  as in every other ancient performing art, is drawn from the
                  epics and the Bhagavatha Purana. There may have been
                  possibilities for embracing secular themes, but the older
                  tradition still persists.
                </Description>
              </>
            )}
          </TextContent>
        </TextColumn>
      </TwoColumn>
    </Container>
  );
};
