import React from "react";
import styled from "styled-components";
import YakshaganaStringPuppetsSection from "../../components/forms/TwoColContactUsWithIllustration";

const SliderMain = styled.div`
  margin-bottom: 50px;
  padding: 100px;
  padding-top: 0;
  @media (min-width: 320px) and (max-width: 480px) {
    padding: 10px;
    margin-left: 0;
    .video-container {
    .videos {
     .video-frame{
        min-height: 300px;
      }
    }
  }
  }
  .image-gallery-slide-wrapper {
    background: black !important;
  }
  .video-container {
    .videos {
      display: block;
      width: 100%;
      margin: 0 auto;
      padding: 1%;
     .video-frame{
        width: 100%;
        max-height: 100%;
        min-height: 600px;
      }
    }
  }

  h1 {
    font-size: 24px;
    font-weight: bold;
  }
`;

export default function Museum() {
  return (
    <div>
      <SliderMain>
        <YakshaganaStringPuppetsSection title={"Museum"} image1={null} />
        <br />
        <div className="video-container">
          <div className="videos">
          <iframe className="video-frame" src="https://www.youtube.com/embed/RuB_gi_jE-c" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen="allowFullScreen"></iframe>
          </div>
        </div>
      </SliderMain>
    </div>
  );
}
