import * as React from "react";
import { PureComponent } from "react";

const CITIES = [
  {
    city: "karnataka",
    state: "karnataka",
    latitude: 13.6596,
    longitude: 74.6913,
  },
  {
    city: " 1978 – FRANCE",
    state: "all",
    latitude: 46.2276,
    longitude: 2.2137,
  },
  {
    city: " 1979 – BELGIUM",
    state: "all",
    latitude: 50.5039,
    longitude: 4.4699,
  },
  {
    city: " 1979 – HOLLAND",
    state: "all",
    latitude: 52.1326,
    longitude: 5.2913,
  },
  {
    city: " 1983 - AUSTRALIA",
    state: "all",
    latitude: 25.2744,
    longitude: 133.7751,
  },
  {
    city: " 1983 - FRANCE",
    state: "all",
    latitude: 46.2276,
    longitude: 2.2137,
  },
  {
    city: " 1983 - GENEVA",
    state: "all",
    latitude: 46.2044,
    longitude: 6.1432,
  },
  {
    city: " 1985 - GERMANY",
    state: "all",
    latitude: 51.1657,
    longitude: 10.4515,
  },
  {
    city: " 1985 - FRANCE",
    state: "all",
    latitude: 46.2276,
    longitude: 2.2137,
  },
  {
    city: " 1985 - HOLLAND",
    state: "all",
    latitude: 52.1326,
    longitude: 5.2913,
  },
  {
    city: " 1985 - REUNION ISLAND",
    state: "all",
    latitude: 21.1151,
    longitude: 55.5364,
  },
  {
    city: " 1986 - GREECE",
    state: "all",
    latitude: 39.0742,
    longitude: 21.8243,
  },
  {
    city: " 1986 - ATHENS",
    state: "all",
    latitude: 37.9838,
    longitude: 23.7275,
  },
  {
    city: " 1986 - HYDRA ISLAND",
    state: "all",
    latitude: 37.3288,
    longitude: 23.4717,
  },
  {
    city: " 1990 - HOLLAND",
    state: "all",
    latitude: 52.1326,
    longitude: 5.2913,
  },
  {
    city: " 1996 - PAKISTAN",
    state: "all",
    latitude: 30.3753,
    longitude: 69.3451,
  },
  {
    city: " 1999 - FRANCE",
    state: "all",
    latitude: 46.2276,
    longitude: 2.2137,
  },
  {
    city: " 2007  - LONDON",
    state: "all",
    latitude: 51.5074,
    longitude: 0.1278,
  },
  {
    city: " 2007 - JAPAN",
    state: "all",
    latitude: 36.2048,
    longitude: 138.2529,
  },
  {
    city: " 2008 - SWITZERLAND",
    state: "all",
    latitude: 46.8182,
    longitude: 8.2275,
  },
  {
    city: " 2009 - SINGAPORE",
    state: "all",
    latitude: 1.3521,
    longitude: 103.8198,
  },
  {
    city: " 2010 - THAILAND",
    state: "all",
    latitude: 15.87,
    longitude: 100.9925,
  },
  {
    city: " 2010 - LONDON",
    state: "all",
    latitude: 51.5074,
    longitude: 0.1278,
  },
  {
    city: " 2012 - JAPAN",
    state: "all",
    latitude: 36.2048,
    longitude: 138.2529,
  },
  {
    city: " 2012 - FRANCE",
    state: "all",
    latitude: 46.2276,
    longitude: 2.2137,
  },
  {
    city: " 2013 - JAPAN",
    state: "all",
    latitude: 36.2048,
    longitude: 138.2529,
  },
  {
    city: " 2013 - RUSSIA",
    state: "all",
    latitude: 61.524,
    longitude: 105.3188,
  },
  {
    city: " 2013 - BELGIUM",
    state: "all",
    latitude: 50.5039,
    longitude: 4.4699,
  },
  {
    city: " 2015 - HONGKONG",
    state: "all",
    latitude: 22.3193,
    longitude: 114.1694,
  },
  {
    city: " 2018 - FRANCE",
    state: "all",
    latitude: 46.2276,
    longitude: 2.2137,
  },
];

export default class ControlPanel extends PureComponent {
  _renderButton = (city, index) => {
    return (
      <div key={`btn-${index}`} className="input">
        <input
          type="radio"
          name="city"
          id={`city-${index}`}
          defaultChecked={city.city === "Los Angeles"}
          onClick={() => this.props.onViewportChange(city)}
        />
        <label htmlFor={`city-${index}`}>{city.city}</label>
      </div>
    );
  };

  render() {
    return (
      <div
        className="control-panel"
        style={{ position: "absolute", top: "10px", left: "50px" }}
      >
        {CITIES.filter((city) => city.state === "all").map(this._renderButton)}
      </div>
    );
  }
}
