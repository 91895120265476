import React, {useState} from "react";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
import {
  SectionHeading,
  Subheading as SubheadingBase,
} from "components/misc/Headings.js";
import axios from 'axios';
import { PrimaryButton as PrimaryButtonBase } from "components/misc/Buttons.js";
import EmailIllustrationSrc from "../../pages/gallery/Gallary/02.Puppets/18GalleryPuppets.jpeg";

const Container = tw.div`relative`;
const TwoColumn = tw.div`flex flex-col md:flex-row justify-between max-w-screen-xl mx-auto py-20 md:py-24`;
const Column = tw.div`w-full max-w-md mx-auto md:max-w-none md:mx-0`;
const ImageColumn = tw(Column)`md:w-5/12 flex-shrink-0 h-80 md:h-auto`;
const TextColumn = styled(Column)((props) => [
  tw`md:w-7/12 mt-16 md:mt-0`,
  props.textOnLeft
    ? tw`md:mr-12 lg:mr-16 md:order-first`
    : tw`md:ml-12 lg:ml-16 md:order-last`,
]);

const Image = styled.div((props) => [
  `background-image: url("${props.imageSrc}");`,
  tw`rounded bg-contain bg-no-repeat bg-center h-full`,
]);
const TextContent = tw.div`lg:py-8 text-center md:text-left`;

const Subheading = tw(SubheadingBase)`text-center md:text-left`;
const Heading = tw(
  SectionHeading
)`mt-4 font-black text-left text-3xl sm:text-4xl lg:text-5xl text-center md:text-left leading-tight`;
const Description = tw.p`mt-4 text-center md:text-left text-sm md:text-base lg:text-lg font-medium leading-relaxed text-secondary-100`;

const Form = tw.form`mt-8 md:mt-10 text-sm flex flex-col max-w-sm mx-auto md:mx-0`;
const Input = tw.input`mt-6 first:mt-0 border-b-2 py-3 focus:outline-none font-medium transition duration-300 hocus:border-primary-500`;
const Textarea = styled(Input).attrs({ as: "textarea" })`
  ${tw`h-24`}
`;

const SubmitButton = tw(PrimaryButtonBase)`inline-block mt-8`;

export default ({
  subheading = "Contact Us",
  heading = (
    <>
      Feel free to <span tw="text-primary-500">get in touch</span>
      <wbr /> with us.
    </>
  ),
  description = "Please fill in the details below and we will get back to you as soon as possible.",
  submitButtonText = "Send",
  formAction = "http://localhost:3000/contact.php",
  formMethod = "get",
  textOnLeft = true,
}) => {
  // The textOnLeft boolean prop can be used to display either the text on left or right side of the image.


  const [email, setEmail] = useState("")
  const [fullname, setFullName] = useState("");
  const [subject, setSubject] = useState("");
  const [message, setMessage] = useState("");
  const [result, setResult] = useState(null);

  const API_PATH = 'https://yakshaganapuppets.org/contact.php';
  const submitForm = (e) => {
    e.preventDefault();
    axios({
      method: 'post',
      url: `${API_PATH}`,
      headers: { 'content-type': 'application/json' },
      data: {
        email, subject, fullname, message
      }
    })
      .then(result => {
        console.log(result);
        setResult(true);
        setEmail("");
        setFullName("");
        setSubject("");
        setMessage("");
      })
      .catch(error => {
        setResult(false)
      });
  }

  const changeEmailValue = (e) => {
    setEmail(e.target.value)
  }
  const changeFullNameValue = (e) => {
    setFullName(e.target.value)
  } 
  const changeSubjectValue = (e) => {
    setSubject(e.target.value)
  } 
  const changeMessgeValue = (e) => {
    setMessage(e.target.value)
  } 

  return (
    <Container>
      <TwoColumn>
        <ImageColumn>
          <Image imageSrc={EmailIllustrationSrc} />
        </ImageColumn>
        <TextColumn textOnLeft={textOnLeft}>
          <TextContent>
            {/* {subheading && <Subheading>{subheading}</Subheading>} */}
            <Heading>{heading}</Heading>
            {description && <Description>{description}</Description>}
            {/* <Form action={formAction} method={formMethod}> */}
            <Form onSubmit={submitForm} >
              <Input
                type="email"
                name="email"
                placeholder="Your Email Address"
                value={email}
                onChange={changeEmailValue}
                required
              />
              <Input onChange={changeFullNameValue} type="text" name="name" placeholder="Full Name" value={fullname} required />
              <Input onChange={changeSubjectValue} type="text" name="subject" placeholder="Subject" value={subject} required />
              <Textarea onChange={changeMessgeValue} name="message" placeholder="Your Message Here" value={message} required />
              <SubmitButton type="submit">{submitButtonText}</SubmitButton>
            </Form>
            <br/>
            <h2 style={{color: 'green'}}>{result === true && "Details submitted Successfully, Our Team will contact "}</h2>
            <h2 style={{color: 'red'}}>{result === false && "Details couldn't be submitted, Kindly contact below details"}</h2>
          </TextContent>
        </TextColumn>
      </TwoColumn>
    </Container>
  );
};
