import image1 from "./Gallary/02.Puppets/01GalleryPuppets.jpeg";
import image2 from "./Gallary/02.Puppets/02GalleryPuppets.jpeg";
import image3 from "./Gallary/02.Puppets/03GalleryPuppets.jpeg";
import image5 from "./Gallary/02.Puppets/04GalleryPuppets.jpeg";
import image6 from "./Gallary/02.Puppets/05GalleryPuppets.jpeg";
import image7 from "./Gallary/02.Puppets/06GalleryPuppets.jpeg";
import image8 from "./Gallary/02.Puppets/07GalleryPuppets.jpeg";
import image9 from "./Gallary/02.Puppets/08GalleryPuppets.jpeg";
import image10 from "./Gallary/02.Puppets/09GalleryPuppets.jpeg";
import image11 from "./Gallary/02.Puppets/10GalleryPuppets.jpeg";
import image12 from "./Gallary/02.Puppets/11GalleryPuppets.jpeg";
import image13 from "./Gallary/02.Puppets/12GalleryPuppets.jpeg";
import image14 from "./Gallary/02.Puppets/13GalleryPuppets.jpeg";
import image15 from "./Gallary/02.Puppets/14GalleryPuppets.jpeg";
import image16 from "./Gallary/02.Puppets/15GalleryPuppets.jpeg";
import image17 from "./Gallary/02.Puppets/16GalleryPuppets.jpeg";
import image18 from "./Gallary/02.Puppets/17GalleryPuppets.jpeg";
import image19 from "./Gallary/02.Puppets/18GalleryPuppets.jpeg";
import image20 from "./Gallary/02.Puppets/19GalleryPuppets.jpeg";
import image4 from "./Gallary/02.Puppets/20GalleryPuppets.jpeg";
import image21 from "./Gallary/02.Puppets/21GalleryPuppets.jpeg";
import image22 from "./Gallary/02.Puppets/22GalleryPuppets.jpeg";
import image23 from "./Gallary/02.Puppets/23GalleryPuppets.jpeg";
import image24 from "./Gallary/02.Puppets/31GalleryPuppets.jpeg";
import image25 from "./Gallary/02.Puppets/30GalleryPuppets.jpg";
import image26 from "./Gallary/02.Puppets/25GalleryPuppets.jpeg";
import image27 from "./Gallary/02.Puppets/26GalleryPuppets.jpeg";
import image28 from "./Gallary/02.Puppets/27GalleryPuppets.jpeg";
import image29 from "./Gallary/02.Puppets/28GalleryPuppets.jpeg";
import image30 from "./Gallary/02.Puppets/29GalleryPuppets.jpeg";

export const photos = [
  {
    src: image1,
  },

  {
    src: image2,
  },

  {
    src: image3,
  },

  {
    src: image4,
  },

  {
    src: image5,
  },

  {
    src: image6,
  },

  {
    src: image7,
  },

  {
    src: image8,
  },
  {
    src: image9,
  },

  {
    src: image10,
  },
  {
    src: image19,
  },
  {
    src: image11,
  },

  {
    src: image12,
  },

  {
    src: image13,
  },

  {
    src: image14,
  },

  {
    src: image15,
  },

  {
    src: image16,
  },

  {
    src: image17,
  },
  {
    src: image18,
  },
  {
    src: image20,
  },
  {
    src: image21,
  },
  {
    src: image22,
  },
  {
    src: image23,
  },
  {
    src: image24,
  },
  {
    src: image25,
  },
  {
    src: image26,
  },
  {
    src: image27,
  },
  {
    src: image28,
  },
  {
    src: image29,
  },
  {
    src: image30,
  },
];
