import React from "react";
import styled from "styled-components";
import tw from "twin.macro";
import HeaderBase from "components/headers/light.js";
import { SectionHeading } from "components/misc/Headings.js";
import { SectionDescription } from "components/misc/Typography.js";
import { PrimaryButton as PrimaryButtonBase } from "components/misc/Buttons.js";
import {
  Container,
  ContentWithVerticalPadding,
} from "components/misc/Layouts.js";
import { ReactComponent as CheckboxIcon } from "feather-icons/dist/icons/check-circle.svg";
import { ReactComponent as QuotesLeftIconBase } from "images/quotes-l.svg";
import { ReactComponent as SvgDecoratorBlob1 } from "images/dot-pattern.svg";
import DevannaKamath from "../../pages/about-us/photos/Kogga D.Kamath/scan0487.jpg";
import DevannaKamath2 from "../../pages/about-us/photos/Kogga D.Kamath/06.soothraparva photo_s 011.jpg";
import DevannaKamath3 from "../../pages/about-us/photos/Kogga D.Kamath/01.Awards Pics - Kogga Devanna Kamath/02.Tulasi Sanmana - Kogga Devanna Kamath.jpg";
import DevannaKamath4 from "../../pages/about-us/photos/Kogga D.Kamath/01.Awards Pics - Kogga Devanna Kamath/01.National Award -Kogga kamath.JPG";

const Header = tw(HeaderBase)`max-w-none`;
const Row = tw.div`flex flex-col lg:flex-row justify-between items-center lg:pt-16 max-w-screen-2xl mx-auto sm:px-8`;
const Column = tw.div``;
const TextColumn = tw(
  Column
)`mr-auto lg:mr-0 max-w-lg lg:max-w-xl xl:max-w-2xl`;
const Heading = tw(
  SectionHeading
)`text-left text-primary-900 leading-snug xl:text-6xl`;
const Description = tw(
  SectionDescription
)`mt-4 lg:text-base text-gray-700 max-w-lg`;
const PrimaryButton = tw(
  PrimaryButtonBase
)`mt-8 inline-block w-56 tracking-wide text-center py-5`;
const FeatureList = tw.ul`mt-12 leading-loose`;
const Feature = tw.li`flex items-center relative`;
const FeatureIcon = tw(CheckboxIcon)`w-5 h-5 text-primary-500 absolute`;
const FeatureText = tw.p`ml-2 font-medium text-gray-700`;
const ImageColumn = tw(Column)`ml-auto lg:mr-0 relative mt-16 lg:mt-0 lg:ml-32`;
const ImageContainer = tw.div`relative z-40 transform xl:-translate-x-24 xl:-translate-y-16`;
const Image = tw.img`max-w-full w-96 rounded-t sm:rounded relative z-20`;
const Offsetbackground = tw.div`absolute inset-0 bg-gray-300 rounded xl:-mb-8`;
const ImageDecoratorBlob = styled(SvgDecoratorBlob1)`
  ${tw`pointer-events-none z-10 absolute right-0 bottom-0 transform translate-x-10 translate-y-10 h-32 w-32 opacity-25 text-gray-900 fill-current`}
`;
const Testimonial = tw.div`max-w-sm rounded-b md:rounded-none relative sm:absolute bottom-0 inset-x-0 z-20 px-8 py-6 sm:px-10 sm:py-8 bg-primary-900 text-gray-400 font-medium transform md:-translate-x-32 text-sm leading-relaxed md:-mr-16 xl:mr-0`;
const QuotesLeftIcon = tw(
  QuotesLeftIconBase
)`w-16 h-16 md:w-12 md:h-12 absolute top-0 left-0 text-gray-100 md:text-red-500 transform translate-x-1 md:-translate-x-1/2 md:-translate-y-5 opacity-10 md:opacity-100`;
const Quote = tw.blockquote``;
const CustomerName = tw.p`mt-4 font-bold`;
const CustomerCompany = tw.p`mt-1 text-sm text-gray-500`;

export default ({
  imageDecoratorBlob = true,
  features1 = [
    "1973 - First Prize awarded by Dr. Shivaram Karanth in the State level puppetry competition held in Heggod",
    "1976 - Fellowship by Delhi's Sangeeth Natak Academy.",
    "1977 - 'Yaksha Putthali Naatya Visharada' Award bestowed upon by Shri T. A. Pai in MGM College.",
    "1978 - First foreign tour to France under the leadership of Mr. K. S. Upadhyaya - Monthlong puppetry show in Raynes.",
    "1978 - Prof. Helstein from California art gallery honoured Kogga Kamath.",
    "1979 - Puppetry show in Holland, France and many other places - felicitation and award by the Ambassador, Holland",
    "1979 - Honorary Fellowship awarded by the Academy of General Education, Manipal.",
  ],
  features2 = [
    "1980 - Selected for monthly honorarium.",
    "1980 - National Award by Delhi Sangeeth Natak Academy.",
    "1982 - Selected member for the Programme Advisory Committee of Mangalore All India Radio.",
    "1983 - Tour to Australia - staged 50 Programmes.",
    "1983 - One month Tour to France, Raynes and Geneva.",
    "1983 - Dr. Padma Subramanyam honoured Kogga Kamath.",
    "1984 - Documentary shooting by Madras Doordarshan on Kogga Kamath's puppetry show.",
    "1984 - Shooting and broadcasting of Kogga Kamath's all puppetry episodes by Delhi Doordarshan.",
    "1986 - 'Rajyothsava Award' given by Chief Minister Mr. Ramakrishna Hegde.",
    "1986 - Appreciation Award given by Prime Minister Mr. Rajiv Gandhi for successfully staging puppetry show in India Festival held in Holland.",
  ],
  features3 = [
    "1990 - Foreign Tour - puppetry show in Holland Festival and other places.",
    "1993 - D.K. District Rajyothsava Award given in Mangalore.",
    "1994 - Tour to Assam - Felicitation in Ravindra Bhavan, Guwahati.",
    "1994 - 'Vishwa Vittala Seva Award' given in Bangalore.",
    "1995 - Prestigious 'Tulsi Samman' Award given in Bharath Bhavan, Bhopal.",
    "1995 - Konkani Academy Award.",
    "1995 - Puppetry Trust formed.",
    " 1996 - Tour to Pakistan - Demonstration given in the Puppetry Festival held in Lahore.",
    "1998 - Felicitation by the 'Dehali Karnataka Sangha' in Delhi.",
    "2003 - 'Gombe Mane' (House of Puppets) established in Uppinakudru, Kundapur Taluk, Kamataka.",
    "2003 - Presentation of Prof. Ku. Shi. Haridasa Bhatta Commemorative Award.",
    "27-8-2003 - The End of a glorious era in the puppetry art with the passing away of a great maestro of puppetry, Kogga Kamath.",
  ],
}) => {
  return (
    <>
      <Container>
        <ContentWithVerticalPadding style={{ marginTop: "120px" }}>
          <Row>
            <TextColumn>
              <Heading style={{ fontSize: "3rem" }}>
                Kogga Devanna Kamath
              </Heading>
              <Description>
                <b>Uppinakudru Kogga Devanna Kamath</b> was born in{" "}
                <b>November 27, 1921</b> as the eldest son of Devanna Padmanabha
                Kamath and Radhabai.
              </Description>
              <Description>
                Late Kogga Kamath together with his son Bhaskar Kogga Kamath has
                participated in many international puppet festivals in{" "}
                <b>
                  <i>
                    France, Geneva, Belgium, Holland, Australia, Greece, Athens,
                    Pakisthan and India
                  </i>
                </b>
                .
              </Description>
            </TextColumn>
            <ImageColumn>
              <ImageContainer>
                <Image src={DevannaKamath2} />
                {imageDecoratorBlob && <ImageDecoratorBlob />}
              </ImageContainer>
              <Offsetbackground />
            </ImageColumn>
          </Row>
        </ContentWithVerticalPadding>

        <ContentWithVerticalPadding>
          <Row>
            <ImageColumn>
              <ImageContainer>
                <Image src={DevannaKamath3} />
                {imageDecoratorBlob && <ImageDecoratorBlob />}
              </ImageContainer>
              <Offsetbackground />
            </ImageColumn>
            <TextColumn style={{ maxWidth: "51rem" }}>
              <Heading style={{ fontSize: "1rem" }}>
                Awards bagged by Kogga Devanna Kamath for his excellence in the
                art of Puppetry and Foreign tours
              </Heading>
              <FeatureList>
                {features1.map((feature, index) => (
                  <Feature key={index}>
                    <FeatureIcon style={{ top: 6, left: -18 }} />
                    <FeatureText>{feature}</FeatureText>
                  </Feature>
                ))}
              </FeatureList>
            </TextColumn>
          </Row>
        </ContentWithVerticalPadding>

        <ContentWithVerticalPadding>
          <Row>
            <TextColumn style={{ maxWidth: "51rem" }}>
              <FeatureList>
                {features2.map((feature, index) => (
                  <Feature key={index}>
                    <FeatureIcon style={{ top: 6, left: -18 }} />
                    <FeatureText>{feature}</FeatureText>
                  </Feature>
                ))}
              </FeatureList>
            </TextColumn>
            <ImageColumn>
              <ImageContainer>
                <Image
                  src={DevannaKamath4}
                  style={{
                    borderRadius: "0",
                    borderTopLeftRadius: "0",
                    borderTopRightRadius: "0",
                  }}
                />
                {imageDecoratorBlob && <ImageDecoratorBlob />}
              </ImageContainer>
              <Offsetbackground />
            </ImageColumn>
          </Row>
        </ContentWithVerticalPadding>

        <ContentWithVerticalPadding>
          <Row>
            <ImageColumn>
              <ImageContainer>
                <Image
                  src={DevannaKamath}
                  style={{
                    borderRadius: "0",
                    borderTopLeftRadius: "0",
                    borderTopRightRadius: "0",
                  }}
                />
                {imageDecoratorBlob && <ImageDecoratorBlob />}
              </ImageContainer>
              <Offsetbackground />
            </ImageColumn>
            <TextColumn style={{ maxWidth: "51rem" }}>
              <FeatureList>
                {features3.map((feature, index) => (
                  <Feature key={index}>
                    <FeatureIcon style={{ top: 6, left: -18 }} />
                    <FeatureText>{feature}</FeatureText>
                  </Feature>
                ))}
              </FeatureList>
            </TextColumn>
          </Row>
        </ContentWithVerticalPadding>
      </Container>
    </>
  );
};
