import React from "react";
import AwardSection from "../../components/hero/BhaskarKogga";

export default function BhaskarKamath() {
  return (
    <div>
      <AwardSection id="about" />
    </div>
  );
}
