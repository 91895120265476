import image1 from "../../../yakshagana/Puppets/04_Performance_Photos/01PerformancePhotos.jpeg";
import image2 from "../../../yakshagana/Puppets/04_Performance_Photos/02PerformancePhotos.jpeg";
import image3 from "../../../yakshagana/Puppets/04_Performance_Photos/03PerformancePhotos.jpeg";
import image4 from "../../../yakshagana/Puppets/04_Performance_Photos/04PerformancePhotos.jpeg";
import image5 from "../../../yakshagana/Puppets/04_Performance_Photos/05PerformancePhotos.jpeg";
import image6 from "../../../yakshagana/Puppets/04_Performance_Photos/06PerformancePhotos.jpeg";
import image7 from "../../../yakshagana/Puppets/04_Performance_Photos/07PerformancePhotos.jpeg";
import image8 from "../../../yakshagana/Puppets/04_Performance_Photos/08PerformancePhotos.jpeg";
import image9 from "../../../yakshagana/Puppets/04_Performance_Photos/09PerformancePhotos.jpeg";
import image10 from "../../../yakshagana/Puppets/04_Performance_Photos/10PerformancePhotos.jpeg";
import image11 from "../../../yakshagana/Puppets/04_Performance_Photos/11PerformancePhotos.jpeg";
import image12 from "../../../yakshagana/Puppets/04_Performance_Photos/12PerformancePhotos.jpeg";
import image13 from "../../../yakshagana/Puppets/04_Performance_Photos/13PerformancePhotos.jpeg";
import image14 from "../../../yakshagana/Puppets/04_Performance_Photos/14PerformancePhotos.jpeg";
import image15 from "../../../yakshagana/Puppets/04_Performance_Photos/15PerformancePhotos.jpeg";
import image16 from "../../../yakshagana/Puppets/04_Performance_Photos/16PerformancePhotos.jpeg";
import image17 from "../../../yakshagana/Puppets/04_Performance_Photos/17PerformancePhotos.jpeg";
import image18 from "../../../yakshagana/Puppets/04_Performance_Photos/18PerformancePhotos.jpeg";
import image19 from "../../../yakshagana/Puppets/04_Performance_Photos/19PerformancePhotos.jpeg";
import image20 from "../../../yakshagana/Puppets/04_Performance_Photos/20PerformancePhotos.jpeg";
import image21 from "../../../yakshagana/Puppets/04_Performance_Photos/21PerformancePhotos.jpeg";
import image22 from "../../../yakshagana/Puppets/04_Performance_Photos/22PerformancePhotos.jpeg";
import image23 from "../../../yakshagana/Puppets/04_Performance_Photos/23PerformancePhotos.jpeg";
import image24 from "../../../yakshagana/Puppets/04_Performance_Photos/24PerformancePhotos.jpeg";
import image25 from "../../../yakshagana/Puppets/04_Performance_Photos/25PerformancePhotos.jpeg";
import image26 from "../../../yakshagana/Puppets/04_Performance_Photos/26PerformancePhotos.jpeg";
import image27 from "../../../yakshagana/Puppets/04_Performance_Photos/27PerformancePhotos.jpeg";
import image28 from "../../../yakshagana/Puppets/04_Performance_Photos/28PerformancePhotos.jpeg";
import image29 from "../../../yakshagana/Puppets/04_Performance_Photos/29PerformancePhotos.jpeg";
import image30 from "../../../yakshagana/Puppets/04_Performance_Photos/30PerformancePhotos.jpeg";
import image31 from "../../../yakshagana/Puppets/04_Performance_Photos/31PerformancePhotos.jpeg";
import image32 from "../../../yakshagana/Puppets/04_Performance_Photos/32PerformancePhotos.jpeg";

export const photos = [
  {
    src: image1,
  },

  {
    src: image2,
  },

  {
    src: image3,
  },

  {
    src: image4,
  },

  {
    src: image5,
  },

  {
    src: image6,
  },

  {
    src: image7,
  },

  {
    src: image8,
  },

  {
    src: image9,
  },

  {
    src: image10,
  },

  {
    src: image11,
  },

  {
    src: image12,
  },

  {
    src: image13,
  },

  {
    src: image14,
  },

  {
    src: image15,
  },

  {
    src: image16,
  },

  {
    src: image17,
  },

  {
    src: image18,
  },

  {
    src: image19,
  },

  {
    src: image20,
  },

  {
    src: image21,
  },

  {
    src: image22,
  },

  {
    src: image23,
  },

  {
    src: image24,
  },

  {
    src: image25,
  },

  {
    src: image26,
  },

  {
    src: image27,
  },

  {
    src: image28,
  },

  {
    src: image29,
  },

  {
    src: image30,
  },

  {
    src: image31,
  },
  {
    src: image32,
  },
];
