import React from "react";
import ImageGallery from "react-image-gallery";
import styled from "styled-components";
import image1 from "./Puppet Academy/02. Kogga Devanna Kamath Awards/2015-v Pai.jpg";
import image2 from "./Puppet Academy/02. Kogga Devanna Kamath Awards/IMG_5229.JPG";
import image3 from "./Puppet Academy/02. Kogga Devanna Kamath Awards/IMG_2931.JPG";
import image4 from "./Puppet Academy/02. Kogga Devanna Kamath Awards/IMG_8759.JPG";
import image5 from "./Puppet Academy/02. Kogga Devanna Kamath Awards/IMG_20190407_154058.jpg";
import YakshaganaStringPuppetsSection from "../../components/forms/TwoColContactUsWithIllustration";

const images = [
  {
    original: image1,
    thumbnail: image1,
    description: "2015 - Sri U.Vaman Pai, Hemmady",
  },
  {
    original: image2,
    thumbnail: image2,
    description: "2016 - Sri  Sridhar Hande, Kota",
  },
  {
    original: image4,
    thumbnail: image4,
    description: "2017 - Sri Mantapa Prabhakar Upadhya, Bengaluru",
  },
  {
    original: image3,
    thumbnail: image3,
    description: "2018 - Sri Vageesh Bhat, Bengaluru",
  },
  {
    original: image5,
    thumbnail: image5,
    description: "2019 - Sri  Nagoor Mahabaleshwara Shet,Nagoor ",
  },
];

const SliderMain = styled.div`
  margin-bottom: 50px;
  padding: 100px;
  padding-top: 0;
  .image-gallery-slide-wrapper {
    background: black !important;
  }
  @media (min-width: 320px) and (max-width: 480px) {
    padding: 10px;
    margin-left: 0;
  }
`;

export default function KoggaDevannaKamathAward() {
  return (
    <div>
      <YakshaganaStringPuppetsSection
        title={"Kogga Devanna Kamath Award"}
        image1={null}
      />
      <SliderMain>
        <ImageGallery items={images} useTranslate3D={true} />
      </SliderMain>
    </div>
  );
}
