import React, {useState} from "react";
import { motion } from "framer-motion";
import tw from "twin.macro";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { css } from "styled-components/macro"; //eslint-disable-line

import useAnimatedNavToggler from "../../helpers/useAnimatedNavToggler.js";

import { ReactComponent as MenuIcon } from "feather-icons/dist/icons/menu.svg";
import { ReactComponent as CloseIcon } from "feather-icons/dist/icons/x.svg";
// import logo from "../../pages/about-us/photos/01.Devanna Padmanabha Kamath/Ganesha Yakshagana Puppets Uppinakudru Logo.png";
import logo from "../../pages/map/logo.jpg";
const Header = tw.header`
  flex justify-between items-center
  max-w-screen-xl mx-auto
`;

export const NavLinks = tw.ul`block`;

/* hocus: stands for "on hover or focus"
 * hocus:bg-primary-700 will apply the bg-primary-700 class on hover or focus
 */
export const NavLinkLi = tw.li`inline-block py-4`;

export const NavLink = styled(NavLinkLi)`
  a {
    ${tw`text-lg py-2 my-2 lg:text-sm lg:mx-6 lg:my-0
    font-semibold tracking-wide transition duration-300
    pb-1 border-b-2 border-transparent hover:border-primary-500 hocus:text-primary-500`};
  }
  &:hover ul.sub_menu {
    ${tw`block`}
  }
`;

export const PrimaryLink = tw(NavLink)`
  lg:mx-0
  px-8 py-3 rounded bg-primary-500 text-gray-100
  hocus:bg-primary-700 hocus:text-gray-200 focus:shadow-outline
  border-b-0
`;

export const LogoLink = styled(NavLink)`
  ${tw`flex items-center font-black border-b-0 text-2xl! ml-0!`};

  img {
    ${tw`w-10 mr-3`}
  }
`;

export const SubMenu = styled(NavLinks)`
  ${tw`hidden absolute z-10
  text-lg lg:text-sm lg:ml-2 lg:mt-4
  font-semibold tracking-wide transition duration-300
  pb-1 border-b-2 border-transparent`};

  background: #ff9900;
  border-radius: 5px;
  padding: 10px 0;
  box-sizing: border-box;

  & > li {
    ${tw`block relative py-2 px-4`}
    &:hover ul.sub_sub_menu {
      ${tw`block`}
    }
    &:only-child {
      color: red;
    }
  }
`;

export const SubSubMenu = styled(NavLinks)`
  ${tw`hidden absolute z-10
  text-lg lg:text-sm lg:-mr-4 lg:mb-4
  font-semibold tracking-wide transition duration-300
  pb-1 border-b-2 border-transparent`};

  top: -10px;
  right: -94%;
  background: #ff9900;
  border-radius: 5px;
  padding: 5px 0;
  box-sizing: border-box;

  li {
    ${tw`block py-2`}
  }
`;

export const MobileNavLinksContainer = tw.nav`flex flex-1 items-center justify-between`;
export const NavToggle = tw.button`
  lg:hidden z-20 focus:outline-none hocus:text-primary-500 transition duration-300
`;
export const MobileNavLinks = motion.custom(styled.div`
  ${tw`lg:hidden z-10 fixed top-0 inset-x-0 border text-center rounded-lg text-gray-900 bg-white`}
  ${NavLinks} {
    ${tw`flex flex-col items-center`}
  }
`);

export const DesktopNavLinks = tw.nav`
  hidden lg:flex flex-1 justify-between items-center
`;

export default ({
  roundedHeaderButton = false,
  logoLink,
  links,
  className,
  collapseBreakpointClass = "lg",
}) => {
  /*
   * This header component accepts an optionals "links" prop that specifies the links to render in the navbar.
   * This links props should be an array of "NavLinks" components which is exported from this file.
   * Each "NavLinks" component can contain any amount of "NavLink" component, also exported from this file.
   * This allows this Header to be multi column.
   * So If you pass only a single item in the array with only one NavLinks component as root, you will get 2 column header.
   * Left part will be LogoLink, and the right part will be the the NavLinks component you
   * supplied.
   * Similarly if you pass 2 items in the links array, then you will get 3 columns, the left will be "LogoLink", the center will be the first "NavLinks" component in the array and the right will be the second "NavLinks" component in the links array.
   * You can also choose to directly modify the links here by not passing any links from the parent component and
   * changing the defaultLinks variable below below.
   * If you manipulate links here, all the styling on the links is already done for you. If you pass links yourself though, you are responsible for styling the links or use the helper styled components that are defined here (NavLink)
   */

  const Container = styled.div`
    position: relative;
    background: #6516ff;
    padding: 5px 15px;
    color: #f9f9f9;
    margin-bottom: 20px;
  `;
  const TwoColumn = tw.div`flex flex-col lg:flex-row lg:items-center max-w-screen-xl mx-auto py-2 md:py-2`;
  const LeftColumn = tw.div`relative lg:w-10/12 text-center max-w-lg mx-auto lg:max-w-none lg:text-left`;
  const RightColumn = tw.div`relative mt-12 lg:mt-0 flex-1 flex flex-col justify-center lg:self-end lg:text-right`;
  const SpanContent = styled.span`
    padding: 10px 15px;
  `;

  const defaultLinks = [
    <NavLinks key={1} style={{ zIndex: 99 }}>
      <NavLink>
        <Link to="/history">History</Link>
      </NavLink>
      <NavLink>
        <Link to="/family-background">About Us</Link>
        <SubMenu key={2} className="sub_menu">
          <NavLink>
            <Link to="/family-background">Family Background</Link>
            <SubSubMenu key={3} className="sub_sub_menu">
              <NavLink>
                <Link to="/kogga-devanna-kamath">Kogga Devanna Kamath</Link>
              </NavLink>
              <NavLink>
                <Link to="/bhaskar-kogga-kamath">Bhaskar Kogga Kamath</Link>
              </NavLink>
            </SubSubMenu>
          </NavLink>
          <NavLink>
            <Link to="/about-us-our-journey">Our Journey</Link>
          </NavLink>
          <NavLink>
            <Link to="/about-us-in-the-news">In The News</Link>
          </NavLink>
        </SubMenu>
      </NavLink>
      <NavLink>
        <Link to="/puppets-yakshagana-string-puppets">Puppets</Link>
        <SubMenu key={3} className="sub_menu">
          <NavLink>
            <Link to="/puppets-yakshagana-string-puppets">
              Yakshagana String Puppets
            </Link>
          </NavLink>
          <NavLink>
            <Link to="/puppets-Requirements">Requirements</Link>
          </NavLink>
          <NavLink>
            <Link to="/puppets-stage-photos">Stage Photos</Link>
          </NavLink>
          <NavLink>
            <Link to="/puppets-performance-photos">Performance Photos</Link>
          </NavLink>
          <NavLink>
            <Link to="/puppets-performance-videos">Performance Videos</Link>
          </NavLink>
        </SubMenu>
      </NavLink>
      <NavLink>
        <Link to="/demo-details-requirements">Demo Details</Link>
        <SubMenu key={3} className="sub_menu">
          <NavLink>
            <Link to="/demo-details-requirements">Requirements</Link>
          </NavLink>
          <NavLink>
            <Link to="/demo-details-demo-photos">Demo Photos</Link>
          </NavLink>
          <NavLink>
            <Link to="/demo-details-demo-videos">Demo Videos</Link>
          </NavLink>
        </SubMenu>
      </NavLink>
      <NavLink>
        <Link to="/about-akademy">Akademy</Link>
        <SubMenu key={4} className="sub_menu">
          <NavLink>
            <Link to="/about-akademy">About Akademy</Link>
          </NavLink>
          <NavLink>
            <Link to="/kogga-devanna-kamath-award">
              Kogga Devanna Kamath Award
            </Link>
          </NavLink>
          <NavLink>
            <Link to="/museum">Museum</Link>
          </NavLink>
          <NavLink>
            <Link to="/akademy-programme-photos">Programme Photos</Link>
          </NavLink>
          <NavLink>
            <Link to="/akademy-programme-videos">Programme Videos</Link>
          </NavLink>
        </SubMenu>
      </NavLink>
      <NavLink>
        <Link to="/gallery-awards">Gallery</Link>
        <SubMenu key={4} className="sub_menu">
          <NavLink>
            <Link to="/gallery-awards">Awards</Link>
          </NavLink>
          <NavLink>
            <Link to="/gallery-puppets">Puppets</Link>
          </NavLink>
          <NavLink>
            <Link to="/gallery-foreign-tours">Foreign Tours</Link>
          </NavLink>
          <NavLink>
            <Link to="/gallery-others">Others</Link>
          </NavLink>
        </SubMenu>
      </NavLink>
      <NavLink>
        <Link to="/appeal-to-public">Appeal To Public</Link>
      </NavLink>
      <NavLink>
        <Link to="/contact-us">Contact Us</Link>
      </NavLink>
    </NavLinks>,
  ];

  const { showNavLinks, animation, toggleNavbar } = useAnimatedNavToggler();
  const [mobileNav, setMobileNav]= useState(false);
  const [linked, setLinked]= useState("");

  const newNav = () =>{
    toggleNavbar();
    setMobileNav(false);
  }

  const newNav2 = (linked) =>{
    setMobileNav(true);
    setLinked(linked)
  }

  const collapseBreakpointCss =
    collapseBreakPointCssMap[collapseBreakpointClass];

  const defaultLogoLink = (
    <Link to="/">
      <img src={logo} alt="logo" style={{ width: "6rem" }} />
    </Link>
  );

  logoLink = logoLink || defaultLogoLink;
  links = links || defaultLinks;

  return (
    <>
      <Container>
        <TwoColumn>
          <LeftColumn className="top-menu">
            <SpanContent>(+91) 94484 37848</SpanContent>
            <SpanContent>
              <a href="mailto:puppeteerkamathbk@yahoo.com">
                puppeteerkamathbk@yahoo.com
              </a>
            </SpanContent>
          </LeftColumn>
          <RightColumn>
            <Link
              style={{
                background: "rgb(50 0 148)",
                textAlign: "center",
                padding: "4px 5px",
                color: "rgb(236 236 236)",
              }}
              to="/contact-us"
            >
              Book Our Show
            </Link>
          </RightColumn>
        </TwoColumn>
      </Container>
      <Header className={className || "header-light"}>
        <DesktopNavLinks css={collapseBreakpointCss.desktopNavLinks}>
          {logoLink}
          {links}
        </DesktopNavLinks>

        <MobileNavLinksContainer
          css={collapseBreakpointCss.mobileNavLinksContainer}
          style={{
            overflowY: "scroll",
            zIndex: 99999,
          }}
        >
          {logoLink}
          <MobileNavLinks
            // onClick={toggleNavbar}
            initial={{ x: "150%", display: "none" }}
            animate={animation}
            css={collapseBreakpointCss.mobileNavLinks}
          >
            <ul>
              <li>
                <Link to="/history" onClick={newNav}>History</Link>
              </li>
              <li>
                <Link to="#" onClick={()=>newNav2('about')}>About Us</Link>
                <ul key={2} className="sub_menu" style={{
                  display: mobileNav && linked === "about" ? 'block' : 'none'
                }}>
                  <li>
                    <Link to="/family-background" onClick={newNav}>Family Background</Link>
                    <ul key={3} className="sub_sub_menu">
                      <li>
                        <Link to="/kogga-devanna-kamath" onClick={newNav}>
                          Kogga Devanna Kamath
                        </Link>
                      </li>
                      <li>
                        <Link to="/bhaskar-kogga-kamath" onClick={newNav}>
                          Bhaskar Kogga Kamath
                        </Link>
                      </li>
                    </ul>
                  </li>
                  <li>
                    <Link to="/about-us-our-journey" onClick={newNav}>Our Journey</Link>
                  </li>
                  <li>
                    <Link to="/about-us-in-the-news" onClick={newNav}>In The News</Link>
                  </li>
                </ul>
              </li>
              <li>
                <Link to="/#" onClick={()=>newNav2('puppets')}>Puppets</Link>
                <ul key={3} className="sub_menu" style={{
                  display: mobileNav && linked === "puppets" ? 'block' : 'none'
                }}>
                  <li>
                    <Link to="/puppets-yakshagana-string-puppets" onClick={newNav}>
                      Yakshagana String Puppets
                    </Link>
                  </li>
                  <li>
                    <Link to="/puppets-Requirements" onClick={newNav}>Requirements</Link>
                  </li>
                  <li>
                    <Link to="/puppets-stage-photos" onClick={newNav}>Stage Photos</Link>
                  </li>
                  <li>
                    <Link to="/puppets-performance-photos" onClick={newNav}>
                      Performance Photos
                    </Link>
                  </li>
                  <li>
                    <Link to="/puppets-performance-videos" onClick={newNav}>
                      Performance Videos
                    </Link>
                  </li>
                </ul>
              </li>
              <li>
                <Link to="#" onClick={()=>newNav2('demo-details')}>Demo Details </Link>
                <ul key={3} className="sub_menu" style={{
                  display: mobileNav && linked === "demo-details" ? 'block' : 'none'
                }}>
                  <li>
                    <Link to="/demo-details-requirements"onClick={newNav}>Requirements</Link>
                  </li>
                  <li>
                    <Link to="/demo-details-demo-photos"onClick={newNav}>Demo Photos</Link>
                  </li>
                  <li>
                    <Link to="/demo-details-demo-videos"onClick={newNav}>Demo Videos</Link>
                  </li>
                </ul>
              </li>
              <li>
                <Link to="/about-akademy" onClick={()=>newNav2('akademy')}>Akademy</Link>
                <ul key={4} className="sub_menu" style={{
                  display: mobileNav && linked === "akademy" ? 'block' : 'none'
                }}>
                  <li>
                    <Link to="/about-akademy" onClick={newNav}>About Akademy</Link>
                  </li>
                  <li>
                    <Link to="/kogga-devanna-kamath-award" onClick={newNav}>
                      Kogga Devanna Kamath Award
                    </Link>
                  </li>
                  <li>
                    <Link to="/museum" onClick={newNav}>Museum</Link>
                  </li>
                  <li>
                    <Link to="/akademy-programme-photos" onClick={newNav}>Programme Photos</Link>
                  </li>
                  <li>
                    <Link to="/akademy-programme-videos" onClick={newNav}>Programme Videos</Link>
                  </li>
                </ul>
              </li>
              <li>
                <Link to="#" onClick={()=>newNav2('gallery')}>Gallery</Link>
                <ul key={4} className="sub_menu" style={{
                  display: mobileNav && linked === "gallery" ? 'block' : 'none'
                }}>
                  <li>
                    <Link to="/gallery-awards" onClick={newNav}>Awards</Link>
                  </li>
                  <li>
                    <Link to="/gallery-puppets" onClick={newNav}>Puppets</Link>
                  </li>
                  <li>
                    <Link to="/gallery-foreign-tours" onClick={newNav}>Foreign Tours</Link>
                  </li>
                  <li>
                    <Link to="/gallery-others" onClick={newNav}>Others</Link>
                  </li>
                </ul>
              </li>
              <li>
                <Link to="/appeal-to-public" onClick={newNav}>Appeal To Public</Link>
              </li>
              <li>
                <Link to="/contact-us" onClick={newNav}>Contact us</Link>
              </li>
            </ul>
          </MobileNavLinks>
          <NavToggle
            onClick={toggleNavbar}
            className={showNavLinks ? "open" : "closed"}
          >
            {showNavLinks ? (
              <CloseIcon tw="w-6 h-6" />
            ) : (
              <MenuIcon tw="w-6 h-6" />
            )}
          </NavToggle>
        </MobileNavLinksContainer>
      </Header>
    </>
  );
};

/* The below code is for generating dynamic break points for navbar.
 * Using this you can specify if you want to switch
 * to the toggleable mobile navbar at "sm", "md" or "lg" or "xl" above using the collapseBreakpointClass prop
 * Its written like this because we are using macros and we can not insert dynamic variables in macros
 */

const collapseBreakPointCssMap = {
  sm: {
    mobileNavLinks: tw`sm:hidden`,
    desktopNavLinks: tw`sm:flex`,
    mobileNavLinksContainer: tw`sm:hidden`,
  },
  md: {
    mobileNavLinks: tw`md:hidden`,
    desktopNavLinks: tw`md:flex`,
    mobileNavLinksContainer: tw`md:hidden`,
  },
  lg: {
    mobileNavLinks: tw`lg:hidden`,
    desktopNavLinks: tw`lg:flex`,
    mobileNavLinksContainer: tw`lg:hidden`,
  },
  xl: {
    mobileNavLinks: tw`lg:hidden`,
    desktopNavLinks: tw`lg:flex`,
    mobileNavLinksContainer: tw`lg:hidden`,
  },
};
