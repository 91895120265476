import image1 from "./Gallary/04. Others (Give Provision to upload the monthly function pic)/IMG_7246.JPG";
import image2 from "./Gallary/04. Others (Give Provision to upload the monthly function pic)/IMG_5374.JPG";
import image3 from "./Gallary/04. Others (Give Provision to upload the monthly function pic)/IMG_2510.JPG";
import image4 from "./Gallary/04. Others (Give Provision to upload the monthly function pic)/IMG_2369.JPG";
import image5 from "./Gallary/04. Others (Give Provision to upload the monthly function pic)/IMG_0719.JPG";
import image6 from "./Gallary/04. Others (Give Provision to upload the monthly function pic)/DSC04982.JPG";
import image7 from "./Gallary/04. Others (Give Provision to upload the monthly function pic)/IMG_20190510_075155.jpg";
import image8 from "./Gallary/04. Others (Give Provision to upload the monthly function pic)/IMG_20180613_171114.jpg";

export const photos = [
  {
    src: image4,
  },
  {
    src: image5,
  },
  {
    src: image6,
  },
  {
    src: image7,
  },
  {
    src: image8,
  },
  {
    src: image1,
  },
  {
    src: image2,
  },
  {
    src: image3,
  },
];
