import React from "react";
import styled from "styled-components";
import video1 from "../academy/Puppet Academy/Akademy Performance Video/00.Akademy Performance Video.mp4";
import video2 from "../academy/Puppet Academy/Akademy Performance Video/01.Akademy Performance Video.mp4";
import video3 from "../academy/Puppet Academy/Akademy Performance Video/02.Akademy Performance Video.mp4";
import YakshaganaStringPuppetsSection from "../../components/forms/TwoColContactUsWithIllustration";

const SliderMain = styled.div`
  margin-bottom: 50px;
  padding: 100px;
  padding-top: 0;
  @media (min-width: 320px) and (max-width: 480px) {
    padding: 10px;
    margin-left: 0;
  }
  .image-gallery-slide-wrapper {
    background: black !important;
  }
  .video-container {
    .videos {
      display: inline-block;
      width: 48%;
      padding: 1%;
    }
  }

  h1 {
    font-size: 24px;
    font-weight: bold;
  }
`;

export default function PerformanceVideos() {
  return (
    <div>
      <SliderMain>
        <YakshaganaStringPuppetsSection
          title={"Performance Videos"}
          image1={null}
        />
        <br />
        <div className="video-container">
          <div className="videos">
          <iframe style={{     width: '100%',
    maxHeight: '100%',
    minHeight: '300px'}} src="https://www.youtube.com/embed/LezQTjGSGGI" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen="allowFullScreen"></iframe>
          </div>
          <div className="videos">
          <iframe style={{     width: '100%',
    maxHeight: '100%',
    minHeight: '300px'}} src="https://www.youtube.com/embed/mn6Fiy8QbCA" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen="allowFullScreen"></iframe>
          </div>
        </div>
      </SliderMain>
    </div>
  );
}
