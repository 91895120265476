import React, { useState } from "react";
import { motion } from "framer-motion";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
import {
  SectionHeading,
  Subheading as SubheadingBase,
} from "components/misc/Headings.js";
import { ReactComponent as PlusIcon } from "feather-icons/dist/icons/plus.svg";
import { ReactComponent as MinusIcon } from "feather-icons/dist/icons/minus.svg";
import Map from "../../pages/map/india-country.svg";

const Container = tw.div`relative`;
const Content = tw.div`max-w-screen-xl mx-auto py-16 lg:py-20`;

const TwoColumn = tw.div`flex`;
const Column = tw.div``;

const Image = styled.div((props) => [
  `background-image: url("${props.imageSrc}");
   background-repeat: no-repeat;
  `,
  props.imageContain ? tw`bg-contain bg-no-repeat` : tw`bg-contain`,
  props.imageShadow ? tw`shadow` : tw`shadow-none`,
  tw`hidden lg:block rounded h-144 bg-center`,
]);

const FAQContent = tw.div`lg:ml-12`;
const Subheading = tw(SubheadingBase)`mb-4 text-center lg:text-left`;
const Heading = tw(SectionHeading)`lg:text-left`;
const Description = tw.p`max-w-xl text-center mx-auto lg:mx-0 lg:text-left lg:max-w-none leading-relaxed text-sm sm:text-base lg:text-lg font-medium mt-4 text-secondary-100`;

const FAQSContainer = tw.dl`mt-12`;
const FAQ = tw.div`cursor-pointer mt-8 select-none border lg:border-0 px-8 py-4 lg:p-0 rounded-lg lg:rounded-none`;
const Question = tw.dt`flex justify-between items-center`;
const QuestionText = tw.span`text-lg lg:text-xl font-semibold`;
const QuestionToggleIcon = styled.span`
  ${tw`ml-2 bg-primary-500 text-gray-100 p-1 rounded-full group-hover:bg-primary-700 group-hover:text-gray-200 transition duration-300`}
  svg {
    ${tw`w-4 h-4`}
  }
`;
const Answer = motion.custom(
  tw.dd`pointer-events-none text-sm sm:text-base leading-relaxed`
);

export default ({
  subheading = "",
  heading = "History",
  description = "Here are some frequently asked questions about our hotels from our loving customers. Should you have any other questions, feel free to reach out via the contact form below.",
  imageSrc = Map,
  imageContain = false,
  imageShadow = true,
  faqs = null,
}) => {
  return (
    <Container>
      <Content>
        <TwoColumn>
          <Column tw="hidden lg:block w-5/12 flex-shrink-0">
            <Image id="map1" imageContain={imageContain} imageSrc={imageSrc} />
          </Column>
          <Column id="history-page">
            <img id="map2" src={Map} alt="map" />

            <FAQContent>
              {subheading ? <Subheading>{subheading}</Subheading> : null}
              <Heading>{heading}</Heading>
              <Description>
                There is evidence to believe that there were more than 30 string
                puppet troupes in the Dakshina Kannada district (Karnataka,
                India) during the period 1910 – 1915 in places like Basrur,
                Barkur, Kokkarne, Mudabidri etc.
              </Description>
              <Description>
                The decline of the families pursuing this theatrical form cannot
                be attributed to acute poverty or to the decline in the people’s
                interest in the entertainment. At any rate the number of
                families engaged in the performance of the puppet plays sharply
                declined. It is a matter of good fortune that the puppet play
                troupe of Uppinakudru, Kundapura Taluk, Dakshina Kannada
                District, maintained its individuality inspite of the vagaries
                of fortune and got the destination of being honored with a
                national Award.
              </Description>
              <Description>
                Uppinakudru is almost an island in the Kundapura taluk. The art
                of puppetry has had a strong foundation here. Though it has
                passed through period crisis it has sustained itself over
                decades and has even revived itself with modifications
                incorporated from time to time.
              </Description>
            </FAQContent>
          </Column>
        </TwoColumn>
      </Content>
    </Container>
  );
};
