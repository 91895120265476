import React from "react";
import styled from "styled-components";
import tw from "twin.macro";
import { ReactComponent as SvgDotPatternIcon } from "../../images/dot-pattern.svg";
import { SectionHeading as HeadingTitle } from "../misc/Headings.js";
import {
  VerticalTimeline,
  VerticalTimelineElement,
} from "react-vertical-timeline-component";
import "react-vertical-timeline-component/style.min.css";

import image1 from "../../pages/about-us/photos/01.Devanna Padmanabha Kamath/02.Wtih Puppet.jpg";
import image2 from "../../pages/about-us/photos/01.Devanna Padmanabha Kamath/01. Award Photos - Devanna Kamath/01.National Award-Devanna Kamath.JPG";
import image3 from "../../pages/about-us/photos/Kogga D.Kamath/01.Awards Pics - Kogga Devanna Kamath/02.Tulasi Sanmana - Kogga Devanna Kamath.jpg";

const Container = tw.div`relative`;

const SingleColumn = tw.div`max-w-screen-xl mx-auto py-20 lg:py-24`;

const HeadingInfoContainer = tw.div`flex flex-col items-center`;
const HeadingDescription = tw.p`mt-4 font-medium text-gray-600 text-center max-w-sm`;

const Content = tw.div`mt-16`;

const Card = styled.div((props) => [
  tw`mt-24 md:flex justify-center items-center`,
  props.reversed ? tw`flex-row-reverse` : "flex-row",
]);
const Image = styled.div((props) => [
  `background-image: url("${props.imageSrc}");`,
  tw`rounded md:w-1/2 lg:w-5/12 xl:w-1/3 flex-shrink-0 h-80 md:h-144 bg-contain bg-no-repeat bg-center mx-4 sm:mx-8 md:mx-4 lg:mx-8`,
]);
const Details = tw.div`mt-4 md:mt-0 md:max-w-md mx-4 sm:mx-8 md:mx-4 lg:mx-8`;
const Subtitle = tw.div`font-bold tracking-wide text-secondary-100`;
const Title = tw.h4`text-3xl font-bold text-gray-900`;
const Description = tw.p`mt-2 text-base leading-loose`;
const Link = tw.a`inline-block mt-4 text-sm text-primary-500 font-bold cursor-pointer transition duration-300 border-b-2 border-transparent hover:border-primary-500`;

const SvgDotPattern1 = tw(
  SvgDotPatternIcon
)`absolute top-0 left-0 transform -translate-x-20 rotate-90 translate-y-8 -z-10 opacity-25 text-primary-500 fill-current w-24`;
const SvgDotPattern2 = tw(
  SvgDotPatternIcon
)`absolute top-0 right-0 transform translate-x-20 rotate-45 translate-y-24 -z-10 opacity-25 text-primary-500 fill-current w-24`;
const SvgDotPattern3 = tw(
  SvgDotPatternIcon
)`absolute bottom-0 left-0 transform -translate-x-20 rotate-45 -translate-y-8 -z-10 opacity-25 text-primary-500 fill-current w-24`;
const SvgDotPattern4 = tw(
  SvgDotPatternIcon
)`absolute bottom-0 right-0 transform translate-x-20 rotate-90 -translate-y-24 -z-10 opacity-25 text-primary-500 fill-current w-24`;

const TimeLineTree = styled.div`
  margin-bottom: 20px;
  .vertical-timeline {
    &:before {
      background: #333;
    }
  }
`;
export default () => {
  const cards = [
    {
      imageSrc: image1,
      subtitle: "Paid",
      title: "Ancient folk",
      description:
        "This ancient folk art was founded by three brothers Laxman, Narasimha and Manjappa Kamath, three centuries ago at Uppinakudru, a small village of Kundapura Taluk. Devanna Padmanabha Kamath, the grand son of Laxman Kamath, persuade this profession with missionary zeal and with the able assistance and patronage of Smt. Kamaladevi Chattopadhyaya, Sri Srinivasa Mallya, Sri K. Sanjeeva Prabhu, Sri K. S. Upadhyaya, Sri Devanna Padmanabha Kamath got National recognition by winning National Award in 1966.",
      url: "https://timerse.com",
    },

    {
      imageSrc: image2,
      subtitle: "Free",
      title: "Sri Devanna Padmanabha Kamath",
      description: `Sri Devanna Padmanabha Kamath breathed his last in 1971 at ripe age of 80 years, his son Guru Sri Kogga Kamath took over and trained many youngsters in this art. Sri K. S. Upadhyaya of Mangalore popularized this art and gave this folk art an international reputation by organizing foreign tours beginning from the year 1978.`,
      description2: `Since then the troupe had chances of exhibiting this unique folk art to foreign audiences many times. Under the able leadership of Sri K. S. Upadhyaya and his wife Dr. Leela Upadhyaya Sri Kogga Kamath staged many shows in the country and abroad and participated in many National and International Festivals.`,
      url: "https://timerse.com",
    },

    {
      imageSrc: image3,
      subtitle: "Exclusive",
      title: "Sri Kogga Devanna Kamath",
      description:
        "Sri Kogga Devanna Kamath also won National Award in 1980, State Award in 1986 and Tulsi Sanman in 1995. Prof. Ku. Shi. Haridas Bhatt was also patronised this rare folk art. Colourful costumes Rhythmic Steps Traditional Music & Dance Kept Alive Through Six Generation. The Family has a image of ganapathi which is more than 300 years old.",
      description2: `String puppets have a special place in the puppet theatre. Daksina Kannada District in Karnataka state is the home of the string puppet theatre. There is evidence to believe that there.`,
      url: "https://timerse.com",
    },
  ];

  return (
    <Container>
      <SingleColumn>
        <HeadingInfoContainer>
          <HeadingTitle style={{ fontSize: "2rem", color: "#3c0e99" }}>
            Family Background of Uppinakudru Yakshagana String Puppetry
          </HeadingTitle>
        </HeadingInfoContainer>

        <Content>
          {cards.map((card, i) => (
            <Card key={i} reversed={i % 2 === 1}>
              <Image imageSrc={card.imageSrc} />
              <Details>
                {/* <Subtitle>{card.subtitle}</Subtitle> */}
                <Title>{card.title}</Title>
                <Description>{card.description}</Description>
                <Description>{card.description2}</Description>
                {/* <Link href={card.url}>See Event Details</Link> */}
              </Details>
            </Card>
          ))}
        </Content>
      </SingleColumn>
      <SvgDotPattern1 />
      <SvgDotPattern2 />
      <SvgDotPattern3 />
      <SvgDotPattern4 />
      <TimeLineTree>
        <HeadingTitle style={{ fontSize: "2rem" }}>
          Lineage of the Kamath family:
        </HeadingTitle>
        <VerticalTimeline>
          <VerticalTimelineElement
            className="vertical-timeline-element--work"
            contentStyle={{ background: "#6617ff", color: "#fff" }}
            iconStyle={{ background: "#6617ff", color: "#fff" }}
          >
            <h3 className="vertical-timeline-element-title">
              Krishna (Babu Kamath)
            </h3>
            <h4 className="vertical-timeline-element-subtitle">
              (1648-1708 approximate)
            </h4>
          </VerticalTimelineElement>
          <VerticalTimelineElement
            className="vertical-timeline-element--education"
            contentStyle={{ background: "#6617ff", color: "#fff" }}
            iconStyle={{ background: "#6617ff", color: "#fff" }}
          >
            <h3 className="vertical-timeline-element-title">
              Lakshmana Kamath, Narasimha, Manjappa and Rangappa
            </h3>
            <h4 className="vertical-timeline-element-subtitle">(1708–1798)</h4>
          </VerticalTimelineElement>
          <VerticalTimelineElement
            className="vertical-timeline-element--education"
            contentStyle={{ background: "#6617ff", color: "#fff" }}
            iconStyle={{ background: "#6617ff", color: "#fff" }}
          >
            <h3 className="vertical-timeline-element-title">
              Venkataramana Kamath, Nagappa Kamath
            </h3>
            <h4 className="vertical-timeline-element-subtitle">(1798–1888)</h4>
          </VerticalTimelineElement>
          <VerticalTimelineElement
            className="vertical-timeline-element--education"
            contentStyle={{ background: "#6617ff", color: "#fff" }}
            iconStyle={{ background: "#6617ff", color: "#fff" }}
          >
            <h3 className="vertical-timeline-element-title">
              Ganapayya Kamath and Srinivas Kamath
            </h3>
            <h4 className="vertical-timeline-element-subtitle">
              Devanna Kamath (1888–1971)
            </h4>{" "}
          </VerticalTimelineElement>
          <VerticalTimelineElement
            className="vertical-timeline-element--education"
            contentStyle={{ background: "#6617ff", color: "#fff" }}
            iconStyle={{ background: "#6617ff", color: "#fff" }}
          >
            <h3 className="vertical-timeline-element-title">Kogga D. Kamath</h3>
            <h4 className="vertical-timeline-element-subtitle">
              (27-11-1921 to 27-08-2003)
            </h4>{" "}
          </VerticalTimelineElement>
          <VerticalTimelineElement
            className="vertical-timeline-element--education"
            contentStyle={{ background: "#6617ff", color: "#fff" }}
            iconStyle={{ background: "#6617ff", color: "#fff" }}
          >
            <h3 className="vertical-timeline-element-title">
              Bhaskar Kogga Kamath, (son of Kogga Kamath)
            </h3>
            <h4 className="vertical-timeline-element-subtitle">Present</h4>
          </VerticalTimelineElement>
        </VerticalTimeline>
      </TimeLineTree>
    </Container>
  );
};
