import React from "react";
import styled from "styled-components";
import YakshaganaStringPuppetsSection from "../../components/forms/TwoColContactUsWithIllustration";
import photo from "../puppets/photos/stage_photos/02.Stage View.JPG";

const SliderMain = styled.div`
  margin-bottom: 50px;
  margin-left: calc(100% - 94vw);
  padding: 100px;
  padding-top: 0;
  @media (min-width: 320px) and (max-width: 480px) {
    padding: 0;
  }
  .image-gallery-slide-wrapper {
    background: black !important;
  }
  h1 {
    font-size: 24px;
    font-weight: bold;
  }
  h2 {
    font-size: 20px;
    font-weight: bold;
  }
  ul {
    margin: 20px 0 20px 50px;
    list-style: decimal-leading-zero;

    li {
      padding: 5px;
    }
  }
`;

export default function Requirements() {
  return (
    <div>
      <YakshaganaStringPuppetsSection
        title={"DEMONSTRATION REQUIREMENT"}
        image1={null}
      />

      <SliderMain>
        <img src={photo} style={{ margin: "0 auto" }} />
        <br />
        <h2>STAGE</h2>
        <p>
          This world itself is a theatre and we are all the puppets in the hands
          of the ALMIGHTY. “Yakshagana” is a unique style of Human Dance Theatre
          in the coastal line of Karnataka particularly in the coastal belt of
          Karnataka. “Yaksha” means God. “Gana” means song. So, Yakshagana means
          song of God. We are going to present a live demonstration about the
          Yakshagana string puppets. This string puppet play of Karnataka is
          interesting both on account of its techniques as well as its content
          matter. The presentation is highly stylished since it has ot adhere
          strictly to the norms and standards of “Yakshagana Bayalata”, one of
          the most remarkable among the numerous different arts of Karnataka.
        </p>
        <p>
          All the ritualistic rigor of the Yakshagana “Human Theatre” has to be
          observed in its original form of making the yakshagana puppets leap to
          life .At times it is difficult to say whether what one is witnessing
          is a mere show of wooden puppets insert and lifeless, or a serious
          performance by well trained men and women pouring for the in dance,
          song and diologue the whole range of human emotion and passions.
          Practical demonstration gives you inner effort and knowledge of this
          puppet theatre.
        </p>
        <br />
        <h2>DURATION</h2>
        <p>One hour</p>
        <br />
        <h2>REQUIREMENTS FOR DEMONSTRATION</h2>
        <ul>
          <li>
            Platform measuring size 8 feet x 8 feet covered by thick red carpet.{" "}
          </li>
          <li>3 hellogin lights with stand.</li>
          <li>4 microphones with stand.</li>
        </ul>
      </SliderMain>
    </div>
  );
}
