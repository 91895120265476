import * as React from "react";
import { Component } from "react";
import MapGL, { FlyToInterpolator } from "react-map-gl";

import ControlPanel from "./control-panel";

const MAPBOX_TOKEN =
  "pk.eyJ1Ijoia3Jpc2hwcmFiaHUwNyIsImEiOiJja2Z1eGluMHIyMDFpMnJwYWQ5enBzeHQ1In0.GpLes-Ey1Jt_n5qg6LEIjA"; // Set your mapbox token here

export default class Maps extends Component {
  state = {
    viewport: {
      latitude: 37.7751,
      longitude: -122.4193,
      zoom: 11,
      bearing: 0,
      pitch: 0,
    },
  };

  _onViewportChange = (viewport) =>
    this.setState({
      viewport: { ...this.state.viewport, ...viewport },
    });

  _goToViewport = ({ longitude, latitude }) => {
    this._onViewportChange({
      longitude,
      latitude,
      zoom: 11,
      transitionInterpolator: new FlyToInterpolator({ speed: 1.2 }),
      transitionDuration: "auto",
    });
  };

  render() {
    const { viewport, settings } = this.state;

    return (
      <div style={{ height: "100%" }}>
        <MapGL
          {...viewport}
          {...settings}
          width="100%"
          height="900px"
          mapStyle="mapbox://styles/mapbox/light-v9"
          onViewportChange={this._onViewportChange}
          dragRotate={false}
          mapboxApiAccessToken={MAPBOX_TOKEN}
        />
        <ControlPanel
          containerComponent={this.props.containerComponent}
          onViewportChange={this._goToViewport}
        />
      </div>
    );
  }
}
