import React from "react";
import YakshaganaStringPuppetsSection from "../../components/forms/TwoColContactUsWithIllustration";
import ImageGallery from "react-image-gallery";
import "./index.css";
import styled from "styled-components";
import image1 from "./photos/slider/04.Ravana.jpg";
import image2 from "./photos/slider/05.Lankini.jpg";
import image3 from "./photos/slider/01.Krishna.jpg";
import image4 from "./photos/slider/07.Homa Havana.jpg";
import image5 from "./photos/slider/08.Sugreeva.JPG";
import image6 from "./photos/slider/03.Ravana Darbar.JPG";
import image7 from "./photos/slider/02.Main Character.jpg";
import image8 from "./photos/slider/06.Jambumali with Ravan.jpg";

const images = [
  {
    original: image1,
    thumbnail: image1,
  },
  {
    original: image2,
    thumbnail: image2,
  },
  {
    original: image3,
    thumbnail: image3,
  },
  {
    original: image4,
    thumbnail: image4,
  },
  {
    original: image5,
    thumbnail: image5,
  },
  {
    original: image6,
    thumbnail: image6,
  },
  {
    original: image7,
    thumbnail: image7,
  },
  {
    original: image8,
    thumbnail: image8,
  },
];

const SliderMain = styled.div`
  margin-bottom: 50px;
  padding: 100px;
  .image-gallery-slide-wrapper {
    background: black !important;
  }
`;

export default function YakshaganaStringPuppets() {
  return (
    <div>
      <YakshaganaStringPuppetsSection
        title={"Yakshagana String Puppets"}
        image1={image6}
      />
      <SliderMain>
        <ImageGallery items={images} />
      </SliderMain>
    </div>
  );
}
