import React from "react";
import styled from "styled-components";
import Maps from "./Maps";
import YakshaganaStringPuppetsSection from "../../components/forms/TwoColContactUsWithIllustration";

const Text = styled.p`
  padding: 20px 50px;
  font-size: 20px;
`;
export default function OurJourney() {
  return (
    <>
      <YakshaganaStringPuppetsSection title={"Our Journey"} image1={null} />
      <Text>
        Our Journey from a small town Uppinakudru in Udupi District(Karnataka)
        to the below places in the world.
      </Text>
      <div style={{ position: "relative" }}>
        <Maps />
      </div>
    </>
  );
}
