import image1 from "./stage_photos/04.Stage Base.JPG";
import image2 from "./stage_photos/04.Stage Base.JPG";
import image3 from "./stage_photos/03.Stage View.JPG";
import image4 from "./stage_photos/02.Stage View.JPG";
import image5 from "./stage_photos/12.Puppet Stand.JPG";
import image6 from "./stage_photos/11.Puppet Stand.JPG";
import image7 from "./stage_photos/09.Stage Set up.JPG";
import image8 from "./stage_photos/08.Stage Set up.JPG";
import image9 from "./stage_photos/07.Stage Set up.JPG";
import image10 from "./stage_photos/06.Stage Setpup.JPG";
import image11 from "./stage_photos/03.0.Stage View.JPG";
import image12 from "./stage_photos/02.1.Stage View.JPG";
import image13 from "./stage_photos/01.Stage Outer View.JPG";
import image14 from "./stage_photos/10.Stage Setup final.JPG";
import image15 from "./stage_photos/13.Stand with Puppets.JPG";

export const photos = [
  {
    src: image1,
    width: 4,
    height: 3,
  },
  {
    src: image2,
    width: 4,
    height: 3,
  },
  {
    src: image3,
    width: 4,
    height: 3,
  },
  {
    src: image4,
    width: 4,
    height: 3,
  },
  {
    src: image5,
    width: 4,
    height: 3,
  },
  {
    src: image6,
    width: 4,
    height: 3,
  },
  {
    src: image7,
    width: 4,
    height: 3,
  },
  {
    src: image8,
    width: 4,
    height: 3,
  },
  {
    src: image9,
    width: 4,
    height: 3,
  },
  {
    src: image10,
    width: 4,
    height: 3,
  },
  {
    src: image11,
    width: 4,
    height: 3,
  },
  {
    src: image12,
    width: 4,
    height: 3,
  },
  {
    src: image13,
    width: 4,
    height: 3,
  },
  {
    src: image14,
    width: 4,
    height: 3,
  },
  {
    src: image15,
    width: 4,
    height: 3,
  },
];
